import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from "react-redux";
import userReducer from './reducers/UserSlice';
import transactionsReducer from './reducers/TransactionsSlice/TransactionsSlice';
import cardTransactionsReducer from './reducers/CardTransactionsSlice/CardTransactionsSlice';
import mercuryoReducer from './reducers/MercuryoSlice/MercuryoSlice';
import walletReducer from './reducers/WalletSlice/walletSlice';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import { persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';

const persistConfig = {
    key: 'root_redux',
    storage,
    stateReconciler: hardSet,
    blacklist: ['register'], // Add keys to blacklist that might contain non-serializable values
};

const rootReducer = combineReducers({
    userReducer,
    transactionsReducer,
    cardTransactionsReducer,
    mercuryoReducer,
    walletReducer
});

const persistedReducer = persistReducer<ReturnType<typeof rootReducer>>(persistConfig, rootReducer);

export const setupStore = () => {
    return configureStore({
        reducer: persistedReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware({
            serializableCheck: {
                // Ignore these action types
                ignoredActions: ['persist/PERSIST'],
                // Ignore these paths in the state
                ignoredPaths: ['register'],
            },
        }).concat(thunk),
    });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
export const useAppDispatch: () => AppDispatch = useDispatch;
