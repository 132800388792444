export const phonecodes = [
    {"co": "af", "ph": "93", "na": "Afghanistan"},
    {"co": "ax", "ph": "35818", "na": "Aland Islands"},
    {"co": "al", "ph": "355", "na": "Albania"},
    {"co": "dz", "ph": "213", "na": "Algeria"},
    {"co": "ad", "ph": "376", "na": "Andorra"},
    {"co": "ao", "ph": "244", "na": "Angola"},
    {"co": "ai", "ph": "1264", "na": "Anguilla"},
    {"co": "ag", "ph": "1268", "na": "Antigua and Barbuda"},
    {"co": "ar", "ph": "54", "na": "Argentina"},
    {"co": "am", "ph": "374", "na": "Armenia"},
    {"co": "aw", "ph": "297", "na": "Aruba"},
    {"co": "au", "ph": "61", "na": "Australia"},
    {"co": "at", "ph": "43", "na": "Austria"},
    {"co": "az", "ph": "994", "na": "Azerbaijan"},
    {"co": "bs", "ph": "1242", "na": "Bahamas"},
    {"co": "bh", "ph": "973", "na": "Bahrain"},
    {"co": "bd", "ph": "880", "na": "Bangladesh"},
    {"co": "bb", "ph": "1246", "na": "Barbados"},
    {"co": "by", "ph": "375", "na": "Belarus"},
    {"co": "be", "ph": "32", "na": "Belgium"},
    {"co": "bz", "ph": "501", "na": "Belize"},
    {"co": "bj", "ph": "229", "na": "Benin"},
    {"co": "bm", "ph": "1441", "na": "Bermuda"},
    {"co": "bt", "ph": "975", "na": "Bhutan"},
    {"co": "bo", "ph": "591", "na": "Bolivia"},
    {"co": "ba", "ph": "387", "na": "Bosnia"},
    {"co": "bq", "ph": "599", "na": "Bosnia and Herzegovina"},
    {"co": "bw", "ph": "267", "na": "Botswana"},
    {"co": "br", "ph": "55", "na": "Brazil"},
    {"co": "io", "ph": "246", "na": "British Indian Ocean Territory"},
    {"co": "vg", "ph": "1284", "na": "British Virgin Islands"},
    {"co": "bn", "ph": "673", "na": "Brunei"},
    {"co": "bg", "ph": "359", "na": "Bulgaria"},
    {"co": "bf", "ph": "226", "na": "Burkina Faso"},
    {"co": "bi", "ph": "257", "na": "Burundi"},
    {"co": "kh", "ph": "855", "na": "Cambodia"},
    {"co": "cm", "ph": "237", "na": "Cameroon"},
    {"co": "ca", "ph": "1", "na": "Canada"},
    {"co": "cv", "ph": "238", "na": "Cape Verde"},
    {"co": "ky", "ph": "1345", "na": "Cayman islands"},
    {"co": "cf", "ph": "236", "na": "Central African Republic"},
    {"co": "td", "ph": "235", "na": "Chad"},
    {"co": "cl", "ph": "56", "na": "Chile"},
    {"co": "cn", "ph": "86", "na": "China"},
    {"co": "cx", "ph": "61", "na": "Christmas Island"},
    {"co": "cc", "ph": "61", "na": "Cocos Islands"},
    {"co": "co", "ph": "57", "na": "Colombia"},
    {"co": "km", "ph": "269", "na": "Comoros"},
    {"co": "cg", "ph": "242", "na": "Congo"},
    {"co": "ck", "ph": "682", "na": "Cook Islands"},
    {"co": "cr", "ph": "506", "na": "Costa Rica"},
    {"co": "hr", "ph": "385", "na": "Croatia"},
    {"co": "cu", "ph": "53", "na": "Cuba"},
    {"co": "cw", "ph": "599", "na": "Curacao"},
    {"co": "cy", "ph": "357", "na": "Cyprus"},
    {"co": "cz", "ph": "420", "na": "Czech"},
    {"co": "kp", "ph": "850", "na": "Democratic People's Republic of Korea"},
    {"co": "cd", "ph": "243", "na": "Democratic Republic of the Congo"},
    {"co": "dk", "ph": "45", "na": "Denmark"},
    {"co": "dj", "ph": "253", "na": "Djibouti"},
    {"co": "dm", "ph": "1767", "na": "Dominica"},
    {"co": "do", "ph": "1809", "na": "Dominican Republic"},
    {"co": "tl", "ph": "670", "na": "East Timor"},
    {"co": "ec", "ph": "593", "na": "Ecuador"},
    {"co": "eg", "ph": "20", "na": "Egypt"},
    {"co": "gq", "ph": "240", "na": "Equatorial Guinea"},
    {"co": "er", "ph": "291", "na": "Eritrea"},
    {"co": "ee", "ph": "372", "na": "Estonia"},
    {"co": "et", "ph": "251", "na": "Ethiopia"},
    {"co": "fk", "ph": "500", "na": "Falkland Islands"},
    {"co": "fo", "ph": "298", "na": "Faroe Islands"},
    {"co": "fj", "ph": "679", "na": "Fiji"},
    {"co": "fi", "ph": "358", "na": "Finland"},
    {"co": "fr", "ph": "33", "na": "France"},
    {"co": "gf", "ph": "594", "na": "French guiana"},
    {"co": "pf", "ph": "689", "na": "French polynesia"},
    {"co": "ga", "ph": "241", "na": "Gabon"},
    {"co": "gm", "ph": "220", "na": "Gambia"},
    {"co": "ge", "ph": "995", "na": "Georgia"},
    {"co": "de", "ph": "49", "na": "Germany"},
    {"co": "gh", "ph": "233", "na": "Ghana"},
    {"co": "gi", "ph": "350", "na": "Gibraltar"},
    {"co": "gb", "ph": "44", "na": "Great Britain"},
    {"co": "gr", "ph": "30", "na": "Greece"},
    {"co": "gl", "ph": "299", "na": "Greenland"},
    {"co": "gd", "ph": "1473", "na": "Grenada"},
    {"co": "gp", "ph": "590", "na": "Guadeloupe"},
    {"co": "gu", "ph": "1671", "na": "Guam"},
    {"co": "gt", "ph": "502", "na": "Guatemala"},
    {"co": "gn", "ph": "224", "na": "Guinea"},
    {"co": "gw", "ph": "245", "na": "Guinea-Bissau"},
    {"co": "gy", "ph": "592", "na": "Guyana"},
    {"co": "ht", "ph": "509", "na": "Haiti"},
    {"co": "hn", "ph": "504", "na": "Honduras"},
    {"co": "hk", "ph": "852", "na": "Hong Kong"},
    {"co": "hu", "ph": "36", "na": "Hungary"},
    {"co": "is", "ph": "354", "na": "Iceland"},
    {"co": "in", "ph": "91", "na": "India"},
    {"co": "id", "ph": "62", "na": "Indonesia"},
    {"co": "ir", "ph": "98", "na": "Iran"},
    {"co": "iq", "ph": "964", "na": "Iraq"},
    {"co": "ie", "ph": "353", "na": "Ireland"},
    {"co": "il", "ph": "972", "na": "Israel"},
    {"co": "it", "ph": "39", "na": "Italy"},
    {"co": "ci", "ph": "225", "na": "Ivory Coast"},
    {"co": "jm", "ph": "1876", "na": "Jamaica"},
    {"co": "jp", "ph": "81", "na": "Japan"},
    {"co": "jo", "ph": "962", "na": "Jordan"},
    {"co": "kz", "ph": "7", "na": "Kazakhstan"},
    {"co": "ke", "ph": "254", "na": "Kenya"},
    {"co": "ki", "ph": "686", "na": "Kiribati"},
    {"co": "kw", "ph": "965", "na": "Kuwait"},
    {"co": "kg", "ph": "996", "na": "Kyrgyzstan"},
    {"co": "la", "ph": "856", "na": "Laos"},
    {"co": "lv", "ph": "371", "na": "Latvia"},
    {"co": "lb", "ph": "961", "na": "Lebanon"},
    {"co": "ls", "ph": "266", "na": "Lesotho"},
    {"co": "lr", "ph": "231", "na": "Liberia"},
    {"co": "ly", "ph": "218", "na": "Libya"},
    {"co": "li", "ph": "423", "na": "Liechtenstein"},
    {"co": "lt", "ph": "370", "na": "Lithuania"},
    {"co": "lu", "ph": "352", "na": "Luxembourg"},
    {"co": "mo", "ph": "853", "na": "Macao"},
    {"co": "mk", "ph": "389", "na": "Macedonia"},
    {"co": "mg", "ph": "261", "na": "Madagascar"},
    {"co": "mw", "ph": "265", "na": "Malawi"},
    {"co": "my", "ph": "60", "na": "Malaysia"},
    {"co": "mv", "ph": "960", "na": "Maldives"},
    {"co": "ml", "ph": "223", "na": "Mali"},
    {"co": "mt", "ph": "356", "na": "Malta"},
    {"co": "mh", "ph": "692", "na": "Marshall Islands"},
    {"co": "mq", "ph": "596", "na": "Martinique"},
    {"co": "mr", "ph": "222", "na": "Mauritania"},
    {"co": "mu", "ph": "230", "na": "Mauritius"},
    {"co": "yt", "ph": "262", "na": "Mayotte"},
    {"co": "mx", "ph": "52", "na": "Mexico"},
    {"co": "um", "ph": "1", "na": "Minor Outlying Islands (USA)"},
    {"co": "md", "ph": "373", "na": "Moldova"},
    {"co": "mc", "ph": "377", "na": "Monaco"},
    {"co": "mn", "ph": "976", "na": "Mongolia"},
    {"co": "me", "ph": "382", "na": "Montenegro"},
    {"co": "ms", "ph": "1664", "na": "Montserrat"},
    {"co": "ma", "ph": "212", "na": "Morocco"},
    {"co": "mz", "ph": "258", "na": "Mozambique"},
    {"co": "mm", "ph": "95", "na": "Myanmar"},
    {"co": "na", "ph": "264", "na": "Namibia"},
    {"co": "nr", "ph": "674", "na": "Nauru"},
    {"co": "np", "ph": "977", "na": "Nepal"},
    {"co": "nl", "ph": "31", "na": "Netherlands"},
    {"co": "nc", "ph": "687", "na": "New Caledonia"},
    {"co": "nz", "ph": "64", "na": "New Zealand"},
    {"co": "ni", "ph": "505", "na": "Nicaragua"},
    {"co": "ne", "ph": "227", "na": "Niger"},
    {"co": "ng", "ph": "234", "na": "Nigeria"},
    {"co": "nu", "ph": "683", "na": "Niue"},
    {"co": "nf", "ph": "672", "na": "Norfolk Island"},
    {"co": "mp", "ph": "1670", "na": "Northern Mariana Islands"},
    {"co": "no", "ph": "47", "na": "Norway"},
    {"co": "om", "ph": "968", "na": "Oman"},
    {"co": "pk", "ph": "92", "na": "Pakistan"},
    {"co": "pw", "ph": "680", "na": "Palau"},
    {"co": "ps", "ph": "970", "na": "Palestinian territories"},
    {"co": "pa", "ph": "507", "na": "Panama"},
    {"co": "pg", "ph": "675", "na": "Papua New Guinea"},
    {"co": "py", "ph": "595", "na": "Paraguay"},
    {"co": "pe", "ph": "51", "na": "Peru"},
    {"co": "ph", "ph": "63", "na": "Philippines"},
    {"co": "pn", "ph": "870", "na": "Pitcairn"},
    {"co": "pl", "ph": "48", "na": "Poland"},
    {"co": "pt", "ph": "351", "na": "Portugal"},
    {"co": "pr", "ph": "1787", "na": "Puerto Rico"},
    {"co": "qa", "ph": "974", "na": "Qatar"},
    {"co": "ro", "ph": "40", "na": "Romania"},
    {"co": "ru", "ph": "7", "na": "Russian Federation"},
    {"co": "rw", "ph": "250", "na": "Rwanda"},
    {"co": "bl", "ph": "590", "na": "Saint Bartholomew Island"},
    {"co": "sh", "ph": "290", "na": "Saint Helena"},
    {"co": "kn", "ph": "1869", "na": "Saint Kitts and Nevis"},
    {"co": "lc", "ph": "1758", "na": "Saint Lucia"},
    {"co": "mf", "ph": "590", "na": "Saint Martin's Island"},
    {"co": "pm", "ph": "508", "na": "Saint Pierre and Miquelon"},
    {"co": "vc", "ph": "1784", "na": "Saint Vincent and the Grenadines"},
    {"co": "sv", "ph": "503", "na": "Salvador"},
    {"co": "ws", "ph": "685", "na": "Samoa"},
    {"co": "sm", "ph": "378", "na": "San Marino"},
    {"co": "st", "ph": "239", "na": "Sao Tome and Principe"},
    {"co": "sa", "ph": "966", "na": "Saudi Arabia"},
    {"co": "sn", "ph": "221", "na": "Senegal"},
    {"co": "rs", "ph": "381", "na": "Serbia"},
    {"co": "sc", "ph": "248", "na": "Seychelles"},
    {"co": "sl", "ph": "232", "na": "Sierra Leone"},
    {"co": "sg", "ph": "65", "na": "Singapore"},
    {"co": "sx", "ph": "599", "na": "Sint Maarten"},
    {"co": "sk", "ph": "421", "na": "Slovakia"},
    {"co": "si", "ph": "386", "na": "Slovenia"},
    {"co": "sb", "ph": "677", "na": "Solomon islands"},
    {"co": "so", "ph": "252", "na": "Somalia"},
    {"co": "za", "ph": "27", "na": "South Africa"},
    {"co": "ss", "ph": "211", "na": "South Sudan"},
    {"co": "es", "ph": "34", "na": "Spain"},
    {"co": "lk", "ph": "94", "na": "Sri Lanka"},
    {"co": "sd", "ph": "249", "na": "Sudan"},
    {"co": "sr", "ph": "597", "na": "Suriname"},
    {"co": "sz", "ph": "268", "na": "Swaziland"},
    {"co": "se", "ph": "46", "na": "Sweden"},
    {"co": "ch", "ph": "41", "na": "Switzerland"},
    {"co": "sy", "ph": "963", "na": "Syrian Arab Republic"},
    {"co": "tw", "ph": "886", "na": "Taiwan"},
    {"co": "tj", "ph": "992", "na": "Tajikistan"},
    {"co": "tz", "ph": "255", "na": "Tanzania"},
    {"co": "th", "ph": "66", "na": "Thailand"},
    {"co": "kr", "ph": "82", "na": "The Republic of Korea"},
    {"co": "tg", "ph": "228", "na": "Togo"},
    {"co": "tk", "ph": "690", "na": "Tokelau"},
    {"co": "to", "ph": "676", "na": "Tonga"},
    {"co": "tt", "ph": "1868", "na": "Trinidad and Tobago"},
    {"co": "tn", "ph": "216", "na": "Tunisia"},
    {"co": "tr", "ph": "90", "na": "Turkey"},
    {"co": "tm", "ph": "993", "na": "Turkmenistan"},
    {"co": "tc", "ph": "1649", "na": "Turks and Caicos Islands"},
    {"co": "tv", "ph": "688", "na": "Tuvalu"},
    {"co": "ae", "ph": "971", "na": "UAE"},
    {"co": "ug", "ph": "256", "na": "Uganda"},
    {"co": "ua", "ph": "380", "na": "Ukraine"},
    {"co": "us", "ph": "1", "na": "United States"},
    {"co": "uy", "ph": "598", "na": "Uruguay"},
    {"co": "uz", "ph": "998", "na": "Uzbekistan"},
    {"co": "vu", "ph": "678", "na": "Vanuatu"},
    {"co": "va", "ph": "379", "na": "Vatican"},
    {"co": "ve", "ph": "58", "na": "Venezuela"},
    {"co": "vn", "ph": "84", "na": "Vietnam"},
    {"co": "vi", "ph": "1340", "na": "Virgin Islands (US)"},
    {"co": "wf", "ph": "681", "na": "Wallis and Futuna Islands"},
    {"co": "ye", "ph": "967", "na": "Yemen"},
    {"co": "zm", "ph": "260", "na": "Zambia"},
    {"co": "zw", "ph": "263", "na": "Zimbabwe"},
    {"co": "fm", "ph": "691", "na": "micronesia"}
]

export const countries = [
    "Afghanistan",
    "Aland Islands",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Croatia",
    "Cuba",
    "Curacao",
    "Cyprus",
    "Czech",
    "Democratic People's Republic of Korea",
    "Democratic Republic of the Congo",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "East Timor",
    "Ecuador",
    "Egypt",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French guiana",
    "French polynesia",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Great Britain",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Israel",
    "Italy",
    "Ivory Coast",
    "Jamaica",
    "Japan",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Minor Outlying Islands (USA)",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Bartholomew Island",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin's Island",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Salvador",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon islands",
    "Somalia",
    "South Africa",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "The Republic of Korea",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "UAE",
    "Uganda",
    "Ukraine",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Wallis and Futuna Islands",
    "Yemen",
    "Zambia",
    "Zimbabwe",
]

export const cardCountries = [
    "Austria",
    "Belgium",
    "Bulgaria",
    "Croatia",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Estonia",
    "Finland",
    "France",
    "Germany",
    "Great Britain",
    "Greece",
    "Hungary",
    "Ireland",
    "Italy",
    "Latvia",
    "Lithuania",
    "Luxembourg",
    "Malta",
    "Netherlands",
    "Poland",
    "Portugal",
    "Romania",
    "Slovakia",
    "Slovenia",
    "Spain",
    "Sweden",
    "Switzerland",
    ]

export const subjectThemes = [
    'Account opening',
    'Business account',
    'Account settings',
    'IBAN account',
    'Transfers and payments',
    'Currency conversion',
]

export const paymentRegions = [
    "SWIFT (International Transfer)",
    "SEPA (only for EUR)",
    "SEPA INSTANT (only for EUR)",
    "PAN AFRICA",
    "BACS (only for GBP)",
    "FASTER PAYMENT (only for GBP)",
    "CHAPS (only for GBP)",
    "ACH (only for USD)",
    "INTERAC (only for CAD)",
    "EFT (only for CAD)",
    "Bill Payment (only for CAD)",
]

export const azaCountries = [
  "Nigeria",
  "Ghana",
  "Uganda",
  // "Europe/SEPA",
  // "United States",
  "Morocco",
  "WAEMU Region/XOF",
  "CEMAC Region/XAF",
  "Guinea",
  "South Africa",
  "Botswana",
  "Brazil",
  "Zambia",
  "Kenya",
  "Egypt",
];

export const languages = [
    'EN',
    'FR',
    'RU',
]

export const nigeriaPaymentTypes = [
    "NGN::Bank",
    "NGN::Mobile",
]

export const ghanaPaymentTypes = [
    "GHS::Bank",
    "GHS::Mobile",
]

export const ugandaPaymentTypes = [
    "UGX::Bank",
    "UGX::Mobile",
]

// export const europePaymentTypes = [
//     "EUR::Bank",
//     "GBP::Bank",
// ]

export const usPaymentTypes = [
    "USD::Bank",
]

export const moroccoPaymentTypes = [
    "MAD::Cash",
]

export const waemuPaymentTypes = [
    "XOF::Cash",
    "XOF::Mobile",
    "XOF::Bank",
]

export const cemacPaymentTypes = [
    "XAF::Bank",
    "XAF::Mobile",
]

export const guineaPaymentTypes = [
    "GNF::Mobile",
]

export const southAfricaPaymentTypes = [
    "ZAR::Bank",
]

export const botswanaPaymentTypes = [
    "BWP::Bank",
]

export const brazilPaymentTypes = [
    "PIX Payments",
    "TED Payments",
]

export const zambiaPaymentTypes = [
    "ZMW::Bank",
]

export const kenyaPaymentTypes = [
    "KES::Bank",
    "KES::Mobile",
]

export const egyptPaymentTypes = [
    "EGP::Bank",
    "USD::Bank",
]

export const other = [
    "",
]

export const otherBank =
    {
        name: "",
        value: ""
    }


export const bankCodeListNgnBank = [
    {
        name: "Access Bank",
        value: "044"
    },
    {
        name: "Carbon / One Finance",
        value: "565"
    },
    {
        name: "Citi Bank Group",
        value: "023"
    },
    {
        name: "EcoBank",
        value: "050"
    },
    {
        name: "Fairmoney Microfinance Bank LTD",
        value: "E30"
    },
    {
        name: "FCMB Bank",
        value: "214"
    },
    {
        name: "Fidelity Bank",
        value: "070"
    },
    {
        name: "First Bank of Nigeria",
        value: "011"
    },
    {
        name: "Go Money",
        value: "326"
    },
    {
        name: "Guaranty Trust Bank",
        value: "058"
    },
    {
        name: "Heritage Bank",
        value: "030"
    },
    {
        name: "Jaiz Bank",
        value: "301"
    },
    {
        name: "Keystone",
        value: "082"
    },
    {
        name: "Kuda Microfinance Bank",
        value: "611"
    },
    {
        name: "Moniepoint Microfinance Bank",
        value: "993"
    },
    {
        name: "Opay",
        value: "305"
    },
    {
        name: "Palmpay",
        value: "B54"
    },
    {
        name: "Polaris Bank",
        value: "076"
    },
    {
        name: "Providus Bank",
        value: "101"
    },
    {
        name: "Stanbic IBTC Bank",
        value: "039"
    },
    {
        name: "Standard Chartered Bank PLC",
        value: "068"
    },
    {
        name: "Sterling bank",
        value: "232"
    },
    {
        name: "Suntrust Bank",
        value: "100"
    },
    {
        name: "Union Bank",
        value: "032"
    },
    {
        name: "United Bank for Africa",
        value: "033"
    },
    {
        name: "Unity Bank",
        value: "215"
    },
    {
        name: "VFD Microfinance Bank",
        value: "566"
    },
    {
        name: "Wema Bank",
        value: "035"
    },
    {
        name: "Zenith International",
        value: "057"
    },
]

export const bankCodeListNigeriaUSDBank = [
    {
        name: "Access Bank",
        value: "044"
    },
    {
        name: "FCMB Bank",
        value: "214"
    },
    {
        name: "Fidelity Bank",
        value: "070"
    },
    {
        name: "Union Bank",
        value: "032"
    },
    {
        name: "United Bank for Africa",
        value: "033"
    },
    {
        name: "Zenith International",
        value: "057"
    },
    {
        name: "Polaris Bank",
        value: "076"
    },
]

export const bankCodeListGhanaGHSBank = [
    {
        name: "ABSA Ghana Bank (formerly Barclays)",
        value: "030100"
    },
    {
        name: "Access Bank",
        value: "280100"
    },
    {
        name: "Agricultural Development Bank",
        value: "080100"
    },
    {
        name: "Bank of Africa",
        value: "210100"
    },
    {
        name: "CAL Bank",
        value: "140100"
    },
    {
        name: "Ecobank",
        value: "130100"
    },
    {
        name: "Fidelity Bank",
        value: "240100"
    },
    {
        name: "First Atlantic Bank",
        value: "170100"
    },
    {
        name: "First Bank Nigeria",
        value: "200100"
    },
    {
        name: "First National Bank",
        value: "330100"
    },
    {
        name: "GCB Bank",
        value: "040100"
    },
    {
        name: "Guaranty Trust Bank",
        value: "230100"
    },
    {
        name: "Heritage Bank",
        value: "370100"
    },
    {
        name: "National Investment Bank",
        value: "050100"
    },
    {
        name: "Prudential Bank",
        value: "180100"
    },
    {
        name: "Republic HFC Bank",
        value: "110100"
    },
    {
        name: "Stanbic Bank",
        value: "190100"
    },
    {
        name: "Standard Chartered Bank",
        value: "020100"
    },
    {
        name: "United Bank for Africa",
        value: "060100"
    },
    {
        name: "Zenith Bank",
        value: "120100"
    },
]

export const bankCodeListTEDPayments = [
    {
        name: "Accredito - Sociedade De Crédito Direto",
        value: "406"
    },
    {
        name: "Acesso Soluções De Pagamento - Instituição De Pagamento",
        value: "332"
    },
    {
        name: "Asaas Gestão Financeira Instituição De Pagamento",
        value: "461"
    },
    {
        name: "Banco Abc Brasil",
        value: "246"
    },
    {
        name: "Banco Afinz - Banco Múltiplo",
        value: "299"
    },
    {
        name: "Banco Agibank",
        value: "121"
    },
    {
        name: "Banco Alfa",
        value: "025"
    },
    {
        name: "Banco Arbi",
        value: "213"
    },
    {
        name: "Banco Bari De Investimentos E Financiamentos",
        value: "330"
    },
    {
        name: "Banco Bmg",
        value: "318"
    },
    {
        name: "Banco Bradesco",
        value: "237"
    },
    {
        name: "Banco Bs2",
        value: "218"
    },
    {
        name: "Banco Btg Pactual",
        value: "208"
    },
    {
        name: "Banco Bv",
        value: "413"
    },
    {
        name: "Banco C6",
        value: "336"
    },
    {
        name: "Banco Citibank",
        value: "745"
    },
    {
        name: "Banco Cooperativo Sicoob - Banco Sicoob",
        value: "756"
    },
    {
        name: "Banco Cooperativo Sicredi",
        value: "748"
    },
    {
        name: "Banco Crefisa",
        value: "069"
    },
    {
        name: "Banco Csf",
        value: "368"
    },
    {
        name: "Banco Da Amazonia",
        value: "003"
    },
    {
        name: "Banco Daycoval",
        value: "707"
    },
    {
        name: "Banco Digimais",
        value: "654"
    },
    {
        name: "Banco Digio",
        value: "335"
    },
    {
        name: "Banco Do Brasil",
        value: "001"
    },
    {
        name: "Banco Do Estado De Sergipe",
        value: "047"
    },
    {
        name: "Banco Do Estado Do Pará",
        value: "037"
    },
    {
        name: "Banco Do Estado Do Rio Grande Do Sul",
        value: "041"
    },
    {
        name: "Banco Do Nordeste Do Brasil",
        value: "004"
    },
    {
        name: "Banco Fibra",
        value: "224"
    },
    {
        name: "Banco Genial",
        value: "125"
    },
    {
        name: "Banco Guanabara",
        value: "612"
    },
    {
        name: "Banco Hsbc",
        value: "269"
    },
    {
        name: "Banco Inbursa",
        value: "012"
    },
    {
        name: "Banco Industrial Do Brasil",
        value: "604"
    },
    {
        name: "Banco Inter",
        value: "077"
    },
    {
        name: "Banco J.P. Morgan",
        value: "376"
    },
    {
        name: "Banco Keb Hana Do Brasil",
        value: "757"
    },
    {
        name: "Banco Letsbank",
        value: "630"
    },
    {
        name: "Banco Luso Brasileiro",
        value: "600"
    },
    {
        name: "Banco Master S/A",
        value: "243"
    },
    {
        name: "Banco Mercantil Do Brasil",
        value: "389"
    },
    {
        name: "Banco Modal",
        value: "746"
    },
    {
        name: "Banco Ourinvest",
        value: "712"
    },
    {
        name: "Banco Pan",
        value: "623"
    },
    {
        name: "Banco Randon",
        value: "088"
    },
    {
        name: "Banco Rendimento",
        value: "633"
    },
    {
        name: "Banco Ribeirao Preto",
        value: "741"
    },
    {
        name: "Banco Safra",
        value: "422"
    },
    {
        name: "Banco Santander (Brasil)",
        value: "033"
    },
    {
        name: "Banco Semear",
        value: "743"
    },
    {
        name: "Banco Senff",
        value: "276"
    },
    {
        name: "Banco Sofisa",
        value: "637"
    },
    {
        name: "Banco Topázio",
        value: "082"
    },
    {
        name: "Banco Triangulo",
        value: "634"
    },
    {
        name: "Banco Voiter",
        value: "653"
    },
    {
        name: "Banco Votorantim",
        value: "655"
    },
    {
        name: "Banco Vr",
        value: "610"
    },
    {
        name: "Banco Western Union Do Brasil",
        value: "119"
    },
    {
        name: "Banco Woori Bank Do Brasil",
        value: "124"
    },
    {
        name: "Banco Xp",
        value: "348"
    },
    {
        name: "Banestes Banco Do Estado Do Espirito Santo",
        value: "021"
    },
    {
        name: "Bank Of America Merrill Lynch Banco Múltiplo",
        value: "755"
    },
    {
        name: "Bexs Banco De Câmbio S/A",
        value: "144"
    },
    {
        name: "Bmp Sociedade De Crédito Ao Microempreendedor E A Empresa De Pequeno Porte Ltda.",
        value: "274"
    },
    {
        name: "Bonuspago Sociedade De Crédito Direto",
        value: "408"
    },
    {
        name: "Brb - Banco De Brasilia",
        value: "070"
    },
    {
        name: "Caixa Economica Federal",
        value: "104"
    },
    {
        name: "Capital Consig Sociedade De Crédito Direto",
        value: "465"
    },
    {
        name: "Cartos Sociedade De Crédito Direto",
        value: "324"
    },
    {
        name: "Caruana - Sociedade De Crédito, Financiamento E Investimento",
        value: "130"
    },
    {
        name: "Casa Do Crédito Sociedade De Crédito Ao Microempreendedor",
        value: "159"
    },
    {
        name: "Celcoin Instituicao De Pagamento",
        value: "509"
    },
    {
        name: "Cielo - Instituição De Pagamento",
        value: "362"
    },
    {
        name: "Cobuccio S/A - Sociedade De Crédito, Financiamento E Investimentos",
        value: "402"
    },
    {
        name: "Confederação Nacional Das Cooperativas Centrais De Crédito E Economia Familiar E",
        value: "133"
    },
    {
        name: "Confederação Nacional Das Cooperativas Centrais Unicred Ltda. - Unicred Do Brasi",
        value: "136"
    },
    {
        name: "Cooperativa Central De Crédito - Ailos",
        value: "085"
    },
    {
        name: "Cooperativa De Credito Dos Servidores Da Universidade Federal Do Espirito Santo",
        value: "427"
    },
    {
        name: "Cooperativa De Crédito Mútuo Dos Despachantes De Trânsito De Santa Catarina E Ri",
        value: "016"
    },
    {
        name: "Cooperativa De Crédito Rural Coopavel",
        value: "281"
    },
    {
        name: "Cooperativa De Crédito Rural De Abelardo Luz - Sulcredi/Crediluz",
        value: "322"
    },
    {
        name: "Cooperativa De Crédito Rural De Pequenos Agricultores E Da Reforma Agrária Do Ce",
        value: "350"
    },
    {
        name: "Cooperativa De Crédito Rural De São Miguel Do Oeste - Sulcredi/São Miguel",
        value: "273"
    },
    {
        name: "Cooperativa De Credito Rural Seara - Crediseara",
        value: "430"
    },
    {
        name: "Cora Sociedade De Crédito Direto",
        value: "403"
    },
    {
        name: "Credialiança Cooperativa De Crédito Rural",
        value: "098"
    },
    {
        name: "Credicoamo Credito Rural Cooperativa",
        value: "010"
    },
    {
        name: "Credifit Sociedade De Crédito Direto",
        value: "452"
    },
    {
        name: "Credisan Cooperativa De Crédito",
        value: "089"
    },
    {
        name: "Credisis - Central De Cooperativas De Crédito Ltda.",
        value: "097"
    },
    {
        name: "Credsystem Sociedade De Crédito Direto",
        value: "428"
    },
    {
        name: "Delcred Sociedade De Crédito Direto",
        value: "435"
    },
    {
        name: "Dock Instituição De Pagamento",
        value: "301"
    },
    {
        name: "Ebanx Instituicao De Pagamentos Ltda.",
        value: "383"
    },
    {
        name: "Efí - Instituição De Pagamento",
        value: "364"
    },
    {
        name: "Ewally Instituição De Pagamento",
        value: "534"
    },
    {
        name: "Fidúcia Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno Porte L",
        value: "382"
    },
    {
        name: "Fitbank Instituição De Pagamentos Eletrônicos",
        value: "450"
    },
    {
        name: "Gazincred Sociedade De Crédito, Financiamento E Investimento",
        value: "478"
    },
    {
        name: "Global Finanças Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno",
        value: "384"
    },
    {
        name: "Hscm - Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno Porte Lt",
        value: "312"
    },
    {
        name: "Hub Instituição De Pagamento",
        value: "396"
    },
    {
        name: "Id Corretora De Títulos E Valores Mobiliários",
        value: "439"
    },
    {
        name: "Índigo Investimentos Distribuidora De Títulos E Valores Mobiliários Ltda.",
        value: "407"
    },
    {
        name: "Itaú Unibanco",
        value: "341"
    },
    {
        name: "Iugu Instituição De Pagamento",
        value: "401"
    },
    {
        name: "Lar Cooperativa De Crédito - Lar Credi",
        value: "421"
    },
    {
        name: "Listo Sociedade De Credito Direto",
        value: "397"
    },
    {
        name: "Magnum Sociedade De Crédito Direto",
        value: "511"
    },
    {
        name: "Mercado Pago Instituição De Pagamento Ltda.",
        value: "323"
    },
    {
        name: "Microcash Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno Porte",
        value: "537"
    },
    {
        name: "Midway - Crédito, Financiamento E Investimento",
        value: "358"
    },
    {
        name: "Neon Pagamentos - Instituição De Pagamento",
        value: "536"
    },
    {
        name: "Nu Pagamentos - Instituição De Pagamento",
        value: "260"
    },
    {
        name: "Numbrs Sociedade De Crédito Direto",
        value: "419"
    },
    {
        name: "Omni Banco",
        value: "613"
    },
    {
        name: "Ótimo Sociedade De Crédito Direto",
        value: "355"
    },
    {
        name: "Pagseguro Internet Instituição De Pagamento",
        value: "290"
    },
    {
        name: "Paraná Banco",
        value: "254"
    },
    {
        name: "Parati - Credito, Financiamento E Investimento",
        value: "326"
    },
    {
        name: "Pefisa - Crédito, Financiamento E Investimento",
        value: "174"
    },
    {
        name: "Picpay Bank - Banco Original S.A",
        value: "079"
    },
    {
        name: "Picpay Instituição De Pagamento",
        value: "380"
    },
    {
        name: "Pinbank Brasil Instituição De Pagamento",
        value: "529"
    },
    {
        name: "Portoseg - Credito, Financiamento E Investimento",
        value: "468"
    },
    {
        name: "Primacredi Cooperativa De Crédito De Primavera Do Leste",
        value: "279"
    },
    {
        name: "Qi Sociedade De Crédito Direto",
        value: "329"
    },
    {
        name: "Realize Crédito, Financiamento E Investimento",
        value: "374"
    },
    {
        name: "Sisprime Do Brasil - Cooperativa De Crédito",
        value: "084"
    },
    {
        name: "Social Bank Banco Múltiplo S/A",
        value: "412"
    },
    {
        name: "Socinal - Crédito, Financiamento E Investimento",
        value: "425"
    },
    {
        name: "Socred - Sociedade De Crédito Ao Microempreendedor E À Empresa De Pequeno P",
        value: "183"
    },
    {
        name: "Stark Sociedade De Crédito Direto",
        value: "462"
    },
    {
        name: "Stone Instituição De Pagamento",
        value: "197"
    },
    {
        name: "Sumup Sociedade De Crédito Direto",
        value: "404"
    },
    {
        name: "Superdigital Instituição De Pagamento",
        value: "340"
    },
    {
        name: "Travelex Banco De Câmbio",
        value: "095"
    },
    {
        name: "Trinus Sociedade De Crédito Direto",
        value: "444"
    },
    {
        name: "U4c Instituição De Pagamento",
        value: "546"
    },
    {
        name: "Uniprime Central Nacional - Central Nacional De Cooperativa De Credito",
        value: "099"
    },
    {
        name: "Uy3 Sociedade De Crédito Direto S/A",
        value: "457"
    },
    {
        name: "Via Certa Financiadora - Crédito, Financiamento E Investimentos",
        value: "411"
    },
    {
        name: "Vortx Distribuidora De Titulos E Valores Mobiliarios Ltda.",
        value: "310"
    },
    {
        name: "Zema Crédito, Financiamento E Investimento S/A",
        value: "359"
    },
]

export const bankCodeListZARBank = [
    {
        name: "ABSA",
        value: "632005"
    },
    {
        name: "Access Bank",
        value: "410506"
    },
    {
        name: "African Bank",
        value: "430000"
    },
    {
        name: "Albaraka Bank",
        value: "800000"
    },
    {
        name: "Bank of China",
        value: "686000"
    },
    {
        name: "Bank Zero",
        value: "888000"
    },
    {
        name: "Bidvest Bank",
        value: "462005"
    },
    {
        name: "BNP Paribas",
        value: "688000"
    },
    {
        name: "Capitec Bank",
        value: "470010"
    },
    {
        name: "Capitec Business",
        value: "450105"
    },
    {
        name: "Citibank",
        value: "350005"
    },
    {
        name: "China Construction Bank",
        value: "586666"
    },
    {
        name: "Discovery Bank",
        value: "679000"
    },
    {
        name: "Finbond Mutual Bank",
        value: "589000"
    },
    {
        name: "First National Bank",
        value: "250655"
    },
    {
        name: "Grindrod Bank",
        value: "584000"
    },
    {
        name: "Habib Overseas Bank Limited",
        value: "700066"
    },
    {
        name: "HBZ Bank",
        value: "570100"
    },
    {
        name: "HSBC Bank",
        value: "587000"
    },
    {
        name: "ICICI Bank",
        value: "362000"
    },
    {
        name: "Investec",
        value: "580105"
    },
    {
        name: "JP Morgan Bank",
        value: "432000"
    },
    {
        name: "Mercantile Bank",
        value: "450905"
    },
    {
        name: "Nedbank",
        value: "198765"
    },
    {
        name: "Olympus Mobile",
        value: "585001"
    },
    {
        name: "Peoples Bank",
        value: "720026"
    },
    {
        name: "PEP Bank",
        value: "400001"
    },
    {
        name: "Permanent Bank",
        value: "760005"
    },
    {
        name: "Rand Merchant Bank",
        value: "261251"
    },
    {
        name: "Reserve Bank",
        value: "980172"
    },
    {
        name: "SA Post Office",
        value: "460005"
    },
    {
        name: "Sasfin Bank",
        value: "683000"
    },
    {
        name: "Standard Bank",
        value: "051001"
    },
    {
        name: "Standard Chartered Bank",
        value: "730020"
    },
    {
        name: "State Bank of India",
        value: "801000"
    },
    {
        name: "Tyme Bank",
        value: "678910"
    },
    {
        name: "Ubank",
        value: "431010"
    },
    {
        name: "Unibank Limited",
        value: "790005"
    },
    {
        name: "VBS",
        value: "588000"
    },
]

export const bankCodeListKESBank = [
    {
        name: "Absa Bank",
        value: "03"
    },
    {
        name: "African Banking Corporation",
        value: "35"
    },
    {
        name: "Bank of Africa Kenya",
        value: "19"
    },
    {
        name: "Citibank",
        value: "16"
    },
    {
        name: "Co-operative Bank of Kenya",
        value: "11"
    },
    {
        name: "Consolidated Bank of Kenya",
        value: "23"
    },
    {
        name: "Credit Bank Ltd",
        value: "25"
    },
    {
        name: "Diamond Trust Bank",
        value: "63"
    },
    {
        name: "DIB Bank",
        value: "75"
    },
    {
        name: "ECO Bank Kenya",
        value: "43"
    },
    {
        name: "Equity Bank Limited",
        value: "68"
    },
    {
        name: "Family Bank",
        value: "70"
    },
    {
        name: "First Community Bank",
        value: "74"
    },
    {
        name: "Guaranty Trust Bank Kenya",
        value: "53"
    },
    {
        name: "Guardian Bank",
        value: "55"
    },
    {
        name: "Gulf African Bank",
        value: "72"
    },
    {
        name: "Housing Finance Co. Kenya",
        value: "61"
    },
    {
        name: "I&M Bank",
        value: "57"
    },
    {
        name: "Kingdom Bank",
        value: "51"
    },
    {
        name: "Kenya Commercial Bank",
        value: "01"
    },
    {
        name: "KWFT Bank",
        value: "78"
    },
    {
        name: "Mayfair Bank",
        value: "65"
    },
    {
        name: "M-Oriental Commercial Bank Limited",
        value: "14"
    },
    {
        name: "Middle East Bank",
        value: "18"
    },
    {
        name: "National Bank of Kenya",
        value: "12"
    },
    {
        name: "NCBA Bank",
        value: "07"
    },
    {
        name: "Paramount Universal Bank Limited",
        value: "50"
    },
    {
        name: "Prime Bank",
        value: "10"
    },
    {
        name: "Sidian Bank",
        value: "66"
    },
    {
        name: "Stanbic Bank Kenya",
        value: "31"
    },
    {
        name: "Standard Chartered Bank",
        value: "02"
    },
    {
        name: "UBA Kenya Bank Ltd",
        value: "76"
    },
    {
        name: "Victoria Bank Limited",
        value: "54"
    },
]

export const bankCodeListEGPBank = [
    {
        name: "ABU DHABI COMMERCIAL BANK EGYPT",
        value: "0027"
    },
    {
        name: "ABU DHABI ISLAMIC BANK",
        value: "0030"
    },
    {
        name: "AHLY UNITED BANK",
        value: "0020"
    },
    {
        name: "AL AHLI BANK OF KUWAIT - EGYPT S.A.E",
        value: "0018"
    },
    {
        name: "AL BARAKA BANK EGYPT",
        value: "0022"
    },
    {
        name: "ARAB AFRICAN INTERNATIONAL BANK",
        value: "0057"
    },
    {
        name: "ARAB BANK",
        value: "0044"
    },
    {
        name: "ARAB BANKING CORPORATION",
        value: "0040"
    },
    {
        name: "ARAB INVESTMENT BANK",
        value: "0058"
    },
    {
        name: "ARAB INTERNATIONAL BANK",
        value: "0056"
    },
    {
        name: "ATTIJARIWAFA BANK - EGYPT S.A.E",
        value: "0034"
    },
    {
        name: "BANK OF ALEXANDRIA",
        value: "0005"
    },
    {
        name: "BANQUE DU CAIRE",
        value: "0004"
    },
    {
        name: "BANQUE MISR",
        value: "0002"
    },
    {
        name: "BLOM BANK EGYPT",
        value: "0013"
    },
    {
        name: "CITIBANK",
        value: "0043"
    },
    {
        name: "COMMERCIAL INTERNATIONAL BANK",
        value: "0010"
    },
    {
        name: "CREDIT AGRICOLE EGYPT",
        value: "0036"
    },
    {
        name: "EGYPT POST",
        value: "9002"
    },
    {
        name: "EGYPTIAN ARAB LAND BANK",
        value: "0007"
    },
    {
        name: "EGYPTIAN GULF BANK",
        value: "0029"
    },
    {
        name: "EMIRATES NATIONAL BANK OF DUBAI",
        value: "0014"
    },
    {
        name: "EXPORT DEVELOPMENT BANK OF EGYPT",
        value: "0061"
    },
    {
        name: "FAISAL ISLAMIC BANK OF EGYPT",
        value: "0059"
    },
    {
        name: "FIRST ABU DHABI BANK (formerly NATIONAL BANK OF ABU DHABI)",
        value: "0019"
    },
    {
        name: "HOUSING AND DEVELOPMENT BANK",
        value: "0038"
    },
    {
        name: "HSBC",
        value: "0025"
    },
    {
        name: "INDUSTRIAL DEVELOPMENT BANK OF EGYPT",
        value: "0008"
    },
    {
        name: "MASHREQ BANK",
        value: "0046"
    },
    {
        name: "MISR IRAN DEVELOPMENT BANK",
        value: "0033"
    },
    {
        name: "NATIONAL BANK OF EGYPT",
        value: "0003"
    },
    {
        name: "NATIONAL BANK OF GREECE",
        value: "0048"
    },
    {
        name: "NATIONAL BANK OF KUWAIT-EGYPT",
        value: "0023"
    },
    {
        name: "PRINCIPAL BANK FOR DEVELOPMENT AND AGRI.",
        value: "0009"
    },
    {
        name: "QATAR NATIONAL BANK ALAHLI",
        value: "0037"
    },
    {
        name: "SOCIETE ARABE INTER. DE BANQUE",
        value: "0035"
    },
    {
        name: "SUEZ CANAL BANK",
        value: "0017"
    },
    {
        name: "UNITED BANK",
        value: "0031"
    },
]

export const branchCodeListBWPBank = [
    {
        name: "Banc ABC, Airport Junction",
        value: "550567"
    },
    {
        name: "Banc ABC, Fair ground Branch",
        value: "550267"
    },
    {
        name: "Banc ABC, Game City",
        value: "550467"
    },
    {
        name: "Banc ABC, Head Office/Corporate",
        value: "552067"
    },
    {
        name: "Banc ABC, Insurance Branch",
        value: "552267"
    },
    {
        name: "Banc ABC, Item Processing",
        value: "552167"
    },
    {
        name: "Banc ABC, Maun Branch",
        value: "550667"
    },
    {
        name: "Banc ABC, Selibe Phikwe",
        value: "550767"
    },
    {
        name: "Banc ABC, Francistown",
        value: "550367"
    },
    {
        name: "Banc ABC, Palapye",
        value: "550867"
    },
    {
        name: "Banc ABC, Squaremart",
        value: "550167"
    },
    {
        name: "Bank Gaborone, Airport Junction",
        value: "201867"
    },
    {
        name: "Bank Gaborone, Central Processing Centre",
        value: "200567"
    },
    {
        name: "Bank Gaborone, Game City",
        value: "201367"
    },
    {
        name: "Bank Gaborone, Ghanzi Branch",
        value: "201767"
    },
    {
        name: "Bank Gaborone, Head Office",
        value: "200167"
    },
    {
        name: "Bank Gaborone, Home Loans",
        value: "200367"
    },
    {
        name: "Bank Gaborone, Mall & Molepolole Branches",
        value: "200667"
    },
    {
        name: "Bank Gaborone, Francistown",
        value: "201467"
    },
    {
        name: "Bank Gaborone, Gaborone",
        value: "200467"
    },
    {
        name: "Bank Gaborone, Kang",
        value: "201767"
    },
    {
        name: "Bank Gaborone, Treasury",
        value: "200267"
    },
    {
        name: "Bank Of Botswana, Francistown",
        value: "910267"
    },
    {
        name: "Bank Of Botswana, Gaborone",
        value: "910167"
    },
    {
        name: "Bank of Baroda, Gaborone West",
        value: "110367"
    },
    {
        name: "Bank of Baroda, Main Mall",
        value: "110167"
    },
    {
        name: "Bank of Baroda, Francistown",
        value: "110267"
    },
    {
        name: "Bank of India, Gaborone Branch",
        value: "450167"
    },
    {
        name: "Barclays Bank, Airport Junction",
        value: "293467"
    },
    {
        name: "Barclays Bank, Barclays House",
        value: "290267"
    },
    {
        name: "Barclays Bank, Blue Jacket",
        value: "294667"
    },
    {
        name: "Barclays Bank, Carbo Centre",
        value: "290767"
    },
    {
        name: "Barclays Bank, Gaborone Industrial",
        value: "290367"
    },
    {
        name: "Barclays Bank, Gaborone Mall",
        value: "290167"
    },
    {
        name: "Barclays Bank, Gaborone Savings Branch",
        value: "296467"
    },
    {
        name: "Barclays Bank, Gaborone Station",
        value: "293867"
    },
    {
        name: "Barclays Bank, Galo Prestige",
        value: "293767"
    },
    {
        name: "Barclays Bank, Game City",
        value: "293567"
    },
    {
        name: "Barclays Bank, Head Office",
        value: "297867"
    },
    {
        name: "Barclays Bank, Personal Prestige",
        value: "294467"
    },
    {
        name: "Barclays Bank, Selibe Phikwe Prestige",
        value: "294567"
    },
    {
        name: "Barclays Bank, Selibe Phikwe",
        value: "291667"
    },
    {
        name: "Barclays Bank, Tati Nickel",
        value: "294167"
    },
    {
        name: "Barclays Bank, BOFEX",
        value: "290667"
    },
    {
        name: "Barclays Bank, Bobonong",
        value: "292367"
    },
    {
        name: "Barclays Bank, Botshabelo",
        value: "294367"
    },
    {
        name: "Barclays Bank, Broadhurst",
        value: "290467"
    },
    {
        name: "Barclays Bank, Francistown",
        value: "291767"
    },
    {
        name: "Barclays Bank, Galagwe",
        value: "294067"
    },
    {
        name: "Barclays Bank, Ghanzi",
        value: "291167"
    },
    {
        name: "Barclays Bank, Gumare",
        value: "293267"
    },
    {
        name: "Barclays Bank, Jwaneng",
        value: "291067"
    },
    {
        name: "Barclays Bank, Kanye",
        value: "291267"
    },
    {
        name: "Barclays Bank, Kasane",
        value: "292067"
    },
    {
        name: "Barclays Bank, Letlhakane",
        value: "293967"
    },
    {
        name: "Barclays Bank, Lobatse",
        value: "290867"
    },
    {
        name: "Barclays Bank, Mahalapye",
        value: "291367"
    },
    {
        name: "Barclays Bank, Masunga",
        value: "292267"
    },
    {
        name: "Barclays Bank, Maun",
        value: "291967"
    },
    {
        name: "Barclays Bank, Merafhe/Gemvas",
        value: "290567"
    },
    {
        name: "Barclays Bank, Mochudi",
        value: "292467"
    },
    {
        name: "Barclays Bank, Mogoditshane",
        value: "292967"
    },
    {
        name: "Barclays Bank, Molepolole",
        value: "292567"
    },
    {
        name: "Barclays Bank, Moshupa",
        value: "292867"
    },
    {
        name: "Barclays Bank, Orapa",
        value: "290967"
    },
    {
        name: "Barclays Bank, Palapye",
        value: "291567"
    },
    {
        name: "Barclays Bank, Phakalane",
        value: "293667"
    },
    {
        name: "Barclays Bank, Ramotswa",
        value: "292767"
    },
    {
        name: "Barclays Bank, Serowe",
        value: "291467"
    },
    {
        name: "Barclays Bank, Shakawe",
        value: "293367"
    },
    {
        name: "Barclays Bank, Sowa",
        value: "293067"
    },
    {
        name: "Barclays Bank, Tlokweng",
        value: "292667"
    },
    {
        name: "Barclays Bank, Tsabong",
        value: "292167"
    },
    {
        name: "Barclays Bank, Tutume",
        value: "291867"
    },
    {
        name: "Capital Bank, Gaborone Main Branch",
        value: "800267"
    },
    {
        name: "Capital Bank, Head Office",
        value: "800167"
    },
    {
        name: "Capital Bank, Broadhurst",
        value: "800467"
    },
    {
        name: "Capital Bank, Francistown",
        value: "800367"
    },
    {
        name: "Capital Bank, Mogoditshane",
        value: "800567"
    },
    {
        name: "First National Bank, Airport Junction",
        value: "288267"
    },
    {
        name: "First National Bank, Code Line Clearing",
        value: "284367"
    },
    {
        name: "First National Bank, Electronic Banking",
        value: "283767"
    },
    {
        name: "First National Bank, First Card",
        value: "282767"
    },
    {
        name: "First National Bank, First Funding Motor",
        value: "284767"
    },
    {
        name: "First National Bank, Gaborone Industrial",
        value: "281667"
    },
    {
        name: "First National Bank, Gaborone Main",
        value: "281467"
    },
    {
        name: "First National Bank, Gaborone Mall",
        value: "282867"
    },
    {
        name: "First National Bank, Head Office",
        value: "282067"
    },
    {
        name: "First National Bank, Kgale View",
        value: "284567"
    },
    {
        name: "First National Bank, Private Clients",
        value: "285467"
    },
    {
        name: "First National Bank, Property Division",
        value: "281067"
    },
    {
        name: "First National Bank, Rail Park Mall",
        value: "288067"
    },
    {
        name: "First National Bank, Selebi Phikwe",
        value: "285067"
    },
    {
        name: "First National Bank, Wes Bank",
        value: "281567"
    },
    {
        name: "First National Bank, WesBank Francistown",
        value: "283467"
    },
    {
        name: "First National Bank, WesBank Motor",
        value: "283367"
    },
    {
        name: "First National Bank, West Bank Corporate",
        value: "283267"
    },
    {
        name: "First National Bank, Broadhurst",
        value: "281267"
    },
    {
        name: "First National Bank, Corporate",
        value: "282267"
    },
    {
        name: "First National Bank, Francistown",
        value: "281867"
    },
    {
        name: "First National Bank, Ghanzi",
        value: "286067"
    },
    {
        name: "First National Bank, Jwaneng",
        value: "283067"
    },
    {
        name: "First National Bank, Kanye",
        value: "281967"
    },
    {
        name: "First National Bank, Kasane",
        value: "285167"
    },
    {
        name: "First National Bank, Letlhakane",
        value: "285567"
    },
    {
        name: "First National Bank, Lobatse",
        value: "281767"
    },
    {
        name: "First National Bank, Mahalapye",
        value: "282467"
    },
    {
        name: "First National Bank, Maun",
        value: "282367"
    },
    {
        name: "First National Bank, Molepolole",
        value: "285667"
    },
    {
        name: "First National Bank, Palapye",
        value: "283167"
    },
    {
        name: "First National Bank, RMB",
        value: "287867"
    },
    {
        name: "First National Bank, Rverwalk",
        value: "285267"
    },
    {
        name: "First National Bank, Serowe",
        value: "285367"
    },
    {
        name: "First National Bank, Treasury",
        value: "282167"
    },
    {
        name: "Stanbic Bank, CBD Square",
        value: "065167"
    },
    {
        name: "Stanbic Bank, CPS Credit Card",
        value: "065667"
    },
    {
        name: "Stanbic Bank, Credit card",
        value: "065567"
    },
    {
        name: "Stanbic Bank, Fair grounds",
        value: "064967"
    },
    {
        name: "Stanbic Bank, Gaborone Industrial",
        value: "061967"
    },
    {
        name: "Stanbic Bank, Gaborone West",
        value: "060167"
    },
    {
        name: "Stanbic Bank, Head Office",
        value: "060467"
    },
    {
        name: "Stanbic Bank, Selebi Phikwe",
        value: "065767"
    },
    {
        name: "Stanbic Bank, Work Place Banking",
        value: "065867"
    },
    {
        name: "Stanbic Bank, Broadhurst",
        value: "060367"
    },
    {
        name: "Stanbic Bank, Francistown",
        value: "064067"
    },
    {
        name: "Stanbic Bank, Kgale",
        value: "060267"
    },
    {
        name: "Stanbic Bank, Maun",
        value: "064767"
    },
    {
        name: "Stanbic Bank, Mogoditshane",
        value: "065367"
    },
    {
        name: "Stanbic Bank, Molepolole",
        value: "065467"
    },
    {
        name: "Stanbic Bank, Palapye",
        value: "065067"
    },
    {
        name: "Stanbic Bank, VAF",
        value: "060567"
    },
    {
        name: "Standard Chartered Bank, Airport Junction",
        value: "662567"
    },
    {
        name: "Standard Chartered Bank, Francistown Nzamo",
        value: "662667"
    },
    {
        name: "Standard Chartered Bank, Gaborone Industrial",
        value: "662367"
    },
    {
        name: "Standard Chartered Bank, Gaborone Mall",
        value: "662167"
    },
    {
        name: "Standard Chartered Bank, Gaborone Standard House",
        value: "662267"
    },
    {
        name: "Standard Chartered Bank, Game City/Gaborone West",
        value: "662867"
    },
    {
        name: "Standard Chartered Bank, Head Office",
        value: "660167"
    },
    {
        name: "Standard Chartered Bank, Hemamo Centre",
        value: "662767"
    },
    {
        name: "Standard Chartered Bank, Selibe Phikwe",
        value: "661667"
    },
    {
        name: "Standard Chartered Bank, Broadhurst",
        value: "662467"
    },
    {
        name: "Standard Chartered Bank, Francistown",
        value: "661767"
    },
    {
        name: "Standard Chartered Bank, Jwaneng",
        value: "660967"
    },
    {
        name: "Standard Chartered Bank, Lobatse",
        value: "660867"
    },
    {
        name: "Standard Chartered Bank, Mahalapye",
        value: "661367"
    },
    {
        name: "Standard Chartered Bank, Maun",
        value: "661967"
    },
    {
        name: "Standard Chartered Bank, Orapa",
        value: "661867"
    },
    {
        name: "Standard Chartered Bank, Palapye",
        value: "661567"
    },
    {
        name: "Standard Chartered Bank, Serowe",
        value: "661467"
    },
    {
        name: "State Bank of India, CBD Gaborone",
        value: "500167"
    },
]

export const branchCodeListUGXBank = [
    {
        name: "ABC CAPITAL BANK LTD Head Office",
        value: "31001"
    },
    {
        name: "BANK OF AFRICA Arua Branch",
        value: "130854"
    },
    {
        name: "BANK OF AFRICA Equatoria Branch",
        value: "130247"
    },
    {
        name: "BANK OF AFRICA Head Office",
        value: "130447"
    },
    {
        name: "BANK OF AFRICA Jinja Branch",
        value: "130341"
    },
    {
        name: "BANK OF AFRICA Kampala Road",
        value: "130747"
    },
    {
        name: "BANK OF AFRICA Lira Branch",
        value: "131155"
    },
    {
        name: "BANK OF AFRICA Main Branch",
        value: "130147"
    },
    {
        name: "BANK OF AFRICA Mbale Branch",
        value: "131042"
    },
    {
        name: "BANK OF AFRICA Mbarara Branch",
        value: "131262"
    },
    {
        name: "BANK OF AFRICA Ndeeba Branch",
        value: "130647"
    },
    {
        name: "BANK OF AFRICA Ntinda Branch",
        value: "130947"
    },
    {
        name: "BANK OF AFRICA The Park Branch",
        value: "130547"
    },
    {
        name: "BANK OF BARODA Iganga Branch",
        value: "20241"
    },
    {
        name: "BANK OF BARODA Jinja Branch",
        value: "20141"
    },
    {
        name: "BANK OF BARODA Kampala Main Branch",
        value: "20147"
    },
    {
        name: "BANK OF BARODA Kansanga Branch",
        value: "20347"
    },
    {
        name: "BANK OF BARODA Kawempe Branch",
        value: "20447"
    },
    {
        name: "BANK OF BARODA Lira Branch",
        value: "20155"
    },
    {
        name: "BANK OF BARODA Mbale Branch",
        value: "20142"
    },
    {
        name: "BANK OF BARODA Mbarara Branch",
        value: "20162"
    },
    {
        name: "BANK OF BARODA Mukono Branch",
        value: "20547"
    },
    {
        name: "BANK OF BARODA Railway Station Branch",
        value: "20247"
    },
    {
        name: "BANK OF INDIA Head Office",
        value: "34047"
    },
    {
        name: "BANK OF UGANDA Kampala Road",
        value: "990147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Abaita Ababiri",
        value: "13547"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Airport",
        value: "17547"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Arua Branch",
        value: "12654"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Bugolobi Branch",
        value: "10347"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Busia Town Branch",
        value: "11242"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Bussia Border Branch",
        value: "18442"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Entebbe Town",
        value: "14147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Fort Portal Branch",
        value: "14360"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Garden City Prestige Centre",
        value: "11547"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Garden City",
        value: "10447"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Gulu Branch",
        value: "13255"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Hannington Road",
        value: "12047"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Head Office",
        value: "13847"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Hoima Branch",
        value: "13055"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Iganga Branch",
        value: "12741"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Ishaka Branch",
        value: "17762"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Jinja Branch",
        value: "14741"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kabale Branch",
        value: "13361"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kampala Road Branch",
        value: "13447"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kamwokya Branch",
        value: "15847"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kansanga Branch",
        value: "15647"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kasese Branch",
        value: "11860"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Katwe Branch",
        value: "16447"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kawempe Branch",
        value: "10147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Kitgum Branch",
        value: "17355"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Koboko Branch",
        value: "14954"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Lira Branch",
        value: "12555"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Lubowa Branch",
        value: "16147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Lugazi Branch",
        value: "15541"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Lugogo Branch",
        value: "12347"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Luwuum Street Branch",
        value: "12847"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Malaba Branch",
        value: "11642"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Masaka Branch",
        value: "13134"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Masindi Branch",
        value: "12955"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Mbale Branch",
        value: "12442"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Mbarara Branch",
        value: "14062"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Mukono",
        value: "10847"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Nakawa Branch",
        value: "13947"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Nankulabye",
        value: "11947"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Nansana",
        value: "11147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Natete Branch",
        value: "12147"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Ndeeba",
        value: "10647"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Nkozi",
        value: "16234"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Ntinda",
        value: "16947"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Oasis Mall",
        value: "10947"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Old Kampala Branch",
        value: "15047"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Owino Branch",
        value: "14847"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Park Royale Branch",
        value: "10247"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Parliament Avenue",
        value: "11747"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Prime Implex Plaza",
        value: "12247"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Rubaga",
        value: "10547"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Rwenzori Courts",
        value: "13747"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Shauriyako Branch",
        value: "11347"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Soroti Branch",
        value: "18042"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Tankhill Parade Branch",
        value: "13647"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Tororo Branch",
        value: "11442"
    },
    {
        name: "BARCLAYS BANK OF UGANDA Wandegeya Branch",
        value: "15747"
    },
    {
        name: "CAIRO INTERNATIONAL BANK Kampala Branch",
        value: "180147"
    },
    {
        name: "CAIRO INTERNATIONAL BANK Kikuubo Branch",
        value: "180247"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Arua Branch",
        value: "162054"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Bugiri Branch",
        value: "164642"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Bwaise Branch",
        value: "162447"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Corporate Branch",
        value: "162247"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Entebbe Road Branch",
        value: "163047"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Fortportal Branch",
        value: "165560"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT GHoima Branch",
        value: "168047"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT GMbarara Branch",
        value: "165062"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Gulu Branch",
        value: "167555"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Head Office Branch",
        value: "168547"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Ibanda Branch",
        value: "165262"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Ishaka Branch",
        value: "166562"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kabale Branch",
        value: "167061"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kapchorwa",
        value: "163242"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kasese Branch",
        value: "161560"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kiboga Branch",
        value: "169147"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kitgum Branch",
        value: "167455"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kyenjonjo Branch",
        value: "165660"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Kyotera Branch",
        value: "166034"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Lira Branch",
        value: "161055"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Masaka Branch",
        value: "164034"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Masindi Branch",
        value: "168147"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Mbale Branch",
        value: "163142"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Mityana Branch",
        value: "169047"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Mukono Branch",
        value: "164247"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Nakivubo Road Branch",
        value: "162147"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Namirembe Road Branch",
        value: "162547"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Nateete Branch",
        value: "162847"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Nebbi Branch",
        value: "167654"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Ntungamo",
        value: "165362"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Rukungiri Branch",
        value: "165162"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Soroti Branch",
        value: "164542"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Tororo Branch",
        value: "169542"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Wakiso Branch",
        value: "163447"
    },
    {
        name: "CENTENARY RURAL DEVELOPMENT Wobulenzi Branch",
        value: "163547"
    },
    {
        name: "CITIBANK Head Office",
        value: "220147"
    },
    {
        name: "COMMERCIAL BANK OF AFRICA Head Office",
        value: "36001"
    },
    {
        name: "CRANE BANK Gulu Branch",
        value: "170255"
    },
    {
        name: "CRANE BANK Iganga Branch",
        value: "170241"
    },
    {
        name: "CRANE BANK Jinja Branch",
        value: "170141"
    },
    {
        name: "CRANE BANK Kabale Branch",
        value: "170161"
    },
    {
        name: "CRANE BANK Kampala Branch",
        value: "170147"
    },
    {
        name: "CRANE BANK Lira Branch",
        value: "170155"
    },
    {
        name: "CRANE BANK Mbale Branch",
        value: "170142"
    },
    {
        name: "CRANE BANK Mbarara Branch",
        value: "170162"
    },
    {
        name: "DFCU Bank Acacia Avenue",
        value: "50647"
    },
    {
        name: "DFCU Bank Arua Branch",
        value: "50454"
    },
    {
        name: "DFCU Bank Bugolobi Branch",
        value: "54147"
    },
    {
        name: "DFCU Bank Dokolo Branch",
        value: "54755"
    },
    {
        name: "DFCU Bank Gulu Branch",
        value: "50955"
    },
    {
        name: "DFCU Bank Hoima Branch",
        value: "51360"
    },
    {
        name: "DFCU Bank Impala Branch",
        value: "50147"
    },
    {
        name: "DFCU Bank Isingiro Branch",
        value: "54862"
    },
    {
        name: "DFCU Bank Jinja Branch",
        value: "54641"
    },
    {
        name: "DFCU Bank Jinja Road Branch",
        value: "50747"
    },
    {
        name: "DFCU Bank Kikubo Branch",
        value: "54247"
    },
    {
        name: "DFCU Bank Lira Branch",
        value: "50555"
    },
    {
        name: "DFCU Bank Makerere University",
        value: "50847"
    },
    {
        name: "DFCU Bank Masaka Branch",
        value: "50334"
    },
    {
        name: "DFCU Bank Mbale Branch",
        value: "51142"
    },
    {
        name: "DFCU Bank Mbarara Branch",
        value: "51262"
    },
    {
        name: "DFCU Bank Nakasero Branch",
        value: "54447"
    },
    {
        name: "DFCU Bank Ndeeba Branch",
        value: "54547"
    },
    {
        name: "DFCU Bank Nsambya Branch",
        value: "51047"
    },
    {
        name: "DFCU Bank Owino Branch",
        value: "54347"
    },
    {
        name: "DFCU Bank Pader Branch",
        value: "54055"
    },
    {
        name: "DFCU Bank William Street Branch",
        value: "50247"
    },
    {
        name: "DFCU Bank kitgum Branch",
        value: "51455"
    },
    {
        name: "DIAMOND TRUST BANK Arua Branch",
        value: "190854"
    },
    {
        name: "DIAMOND TRUST BANK Busia Branch",
        value: "191047"
    },
    {
        name: "DIAMOND TRUST BANK Industrial Area Branch",
        value: "190947"
    },
    {
        name: "DIAMOND TRUST BANK Jinja Branch",
        value: "190641"
    },
    {
        name: "DIAMOND TRUST BANK Kampala Road Main Branch",
        value: "190147"
    },
    {
        name: "DIAMOND TRUST BANK Kikuubo Branch",
        value: "190247"
    },
    {
        name: "DIAMOND TRUST BANK Kitintale Branch",
        value: "190547"
    },
    {
        name: "DIAMOND TRUST BANK Lira Branch",
        value: "191247"
    },
    {
        name: "DIAMOND TRUST BANK Malaba Branch",
        value: "191347"
    },
    {
        name: "DIAMOND TRUST BANK Nakumatt Oasis Mall Branch",
        value: "191447"
    },
    {
        name: "DIAMOND TRUST BANK Ndeeba Branch",
        value: "191147"
    },
    {
        name: "DIAMOND TRUST BANK Ntinda Branch",
        value: "190747"
    },
    {
        name: "DIAMOND TRUST BANK Old Kampala Branch",
        value: "190347"
    },
    {
        name: "DIAMOND TRUST BANK Wandegeya Branch",
        value: "190447"
    },
    {
        name: "ECOBANK (U) LTD Bombo Road",
        value: "29004"
    },
    {
        name: "ECOBANK (U) LTD Bugema University",
        value: "29009"
    },
    {
        name: "ECOBANK (U) LTD Entebbe",
        value: "29005"
    },
    {
        name: "ECOBANK (U) LTD Forest Mall Lugogo",
        value: "29011"
    },
    {
        name: "ECOBANK (U) LTD Head office Branch",
        value: "29998"
    },
    {
        name: "ECOBANK (U) LTD Jinja",
        value: "29013"
    },
    {
        name: "ECOBANK (U) LTD Kikuubo",
        value: "29002"
    },
    {
        name: "ECOBANK (U) LTD Kyambogo University",
        value: "29010"
    },
    {
        name: "ECOBANK (U) LTD Mukono",
        value: "29003"
    },
    {
        name: "ECOBANK (U) LTD Ndeeba",
        value: "29006"
    },
    {
        name: "ECOBANK (U) LTD Oasis",
        value: "29007"
    },
    {
        name: "ECOBANK (U) LTD Wandegeya",
        value: "29008"
    },
    {
        name: "EQUITY BANK Adjumani",
        value: "301954"
    },
    {
        name: "EQUITY BANK Arua Park",
        value: "303847"
    },
    {
        name: "EQUITY BANK Bombo",
        value: "300647"
    },
    {
        name: "EQUITY BANK Bukuya",
        value: "301847"
    },
    {
        name: "EQUITY BANK Busia",
        value: "300842"
    },
    {
        name: "EQUITY BANK Busiika",
        value: "301747"
    },
    {
        name: "EQUITY BANK Bwera",
        value: "302160"
    },
    {
        name: "EQUITY BANK Hoima",
        value: "302655"
    },
    {
        name: "EQUITY BANK Jinja Road",
        value: "300247"
    },
    {
        name: "EQUITY BANK Jinja",
        value: "302741"
    },
    {
        name: "EQUITY BANK Kabalagala",
        value: "303547"
    },
    {
        name: "EQUITY BANK Kajjansi",
        value: "301047"
    },
    {
        name: "EQUITY BANK Kalwere",
        value: "304147"
    },
    {
        name: "EQUITY BANK Kamwokya",
        value: "300147"
    },
    {
        name: "EQUITY BANK Kasangati",
        value: "300447"
    },
    {
        name: "EQUITY BANK Katwe",
        value: "300347"
    },
    {
        name: "EQUITY BANK Kawempe",
        value: "304247"
    },
    {
        name: "EQUITY BANK Kayunga",
        value: "300547"
    },
    {
        name: "EQUITY BANK Kiboga",
        value: "302347"
    },
    {
        name: "EQUITY BANK Kisizi",
        value: "302061"
    },
    {
        name: "EQUITY BANK Kisoro",
        value: "300747"
    },
    {
        name: "EQUITY BANK KyNigeriaera",
        value: "301247"
    },
    {
        name: "EQUITY BANK Lira",
        value: "302955"
    },
    {
        name: "EQUITY BANK Luwero",
        value: "301147"
    },
    {
        name: "EQUITY BANK Lyantonde",
        value: "301362"
    },
    {
        name: "EQUITY BANK Market Street",
        value: "303247"
    },
    {
        name: "EQUITY BANK Masindi",
        value: "302855"
    },
    {
        name: "EQUITY BANK Mbarara",
        value: "303162"
    },
    {
        name: "EQUITY BANK Mityana",
        value: "301647"
    },
    {
        name: "EQUITY BANK Mubende",
        value: "303047"
    },
    {
        name: "EQUITY BANK Nakawa",
        value: "300947"
    },
    {
        name: "EQUITY BANK Nakulabye",
        value: "303947"
    },
    {
        name: "EQUITY BANK Ndeeba",
        value: "303447"
    },
    {
        name: "EQUITY BANK New Taxi Park",
        value: "304047"
    },
    {
        name: "EQUITY BANK Oasis",
        value: "303647"
    },
    {
        name: "EQUITY BANK Rushere",
        value: "302562"
    },
    {
        name: "EQUITY BANK Tirinyi",
        value: "301442"
    },
    {
        name: "EQUITY BANK Tororo",
        value: "301542"
    },
    {
        name: "EQUITY BANK Venus Plaza",
        value: "303347"
    },
    {
        name: "EQUITY BANK William Street",
        value: "303747"
    },
    {
        name: "EQUITY BANK Zirobwe",
        value: "302247"
    },
    {
        name: "FINCA UGANDA Head Office",
        value: "53001"
    },
    {
        name: "GLOBAL TRUST BANK Bwaise Branch",
        value: "240647"
    },
    {
        name: "GLOBAL TRUST BANK Head Office",
        value: "240147"
    },
    {
        name: "GLOBAL TRUST BANK Mbale Branch",
        value: "240742"
    },
    {
        name: "GLOBAL TRUST BANK Mukono Branch",
        value: "240547"
    },
    {
        name: "GLOBAL TRUST BANK Nakivubo Branch",
        value: "240247"
    },
    {
        name: "GLOBAL TRUST BANK Natete Branch",
        value: "240447"
    },
    {
        name: "GLOBAL TRUST BANK Paidha Branch",
        value: "240954"
    },
    {
        name: "GLOBAL TRUST BANK Pallisa Branch",
        value: "240842"
    },
    {
        name: "GLOBAL TRUST BANK Parliament Branch",
        value: "240347"
    },
    {
        name: "GUARANTEE TRUST BANK Buganda Road Branch",
        value: "271147"
    },
    {
        name: "GUARANTEE TRUST BANK Head Office",
        value: "270147"
    },
    {
        name: "GUARANTEE TRUST BANK Kabale Branch",
        value: "271361"
    },
    {
        name: "GUARANTEE TRUST BANK Mbarara Branch",
        value: "271462"
    },
    {
        name: "GUARANTEE TRUST BANK Nakivubo Branch",
        value: "271247"
    },
    {
        name: "GUARANTEE TRUST BANK Owino Branch",
        value: "271547"
    },
    {
        name: "HOUSING FINANCE BANK Arua Branch",
        value: "230754"
    },
    {
        name: "HOUSING FINANCE BANK Kampala Road",
        value: "230147"
    },
    {
        name: "HOUSING FINANCE BANK Mbale Branch",
        value: "230842"
    },
    {
        name: "HOUSING FINANCE BANK Mbarara Branch",
        value: "230562"
    },
    {
        name: "HOUSING FINANCE BANK Nakasero Branch",
        value: "230247"
    },
    {
        name: "HOUSING FINANCE BANK Namuwongo Branch",
        value: "230347"
    },
    {
        name: "HOUSING FINANCE BANK Ntinda Branch",
        value: "230447"
    },
    {
        name: "IMPERIAL BANK Kyaggwe Road",
        value: "320247"
    },
    {
        name: "KCB BANK UGANDA LTD Arua",
        value: "253354"
    },
    {
        name: "KCB BANK UGANDA LTD Ben Kiwanuka Branch",
        value: "253547"
    },
    {
        name: "KCB BANK UGANDA LTD Elgon Masaba",
        value: "255042"
    },
    {
        name: "KCB BANK UGANDA LTD Fort Portal Branch",
        value: "253960"
    },
    {
        name: "KCB BANK UGANDA LTD Gulu Branch",
        value: "253855"
    },
    {
        name: "KCB BANK UGANDA LTD Head Office",
        value: "252947"
    },
    {
        name: "KCB BANK UGANDA LTD Hoima Branch",
        value: "253655"
    },
    {
        name: "KCB BANK UGANDA LTD Jinja",
        value: "255141"
    },
    {
        name: "KCB BANK UGANDA LTD Kampala Road Branch",
        value: "253047"
    },
    {
        name: "KCB BANK UGANDA LTD Lira Branch",
        value: "253155"
    },
    {
        name: "KCB BANK UGANDA LTD Luwum Street Branch",
        value: "253247"
    },
    {
        name: "KCB BANK UGANDA LTD Mbarara Branch",
        value: "253462"
    },
    {
        name: "KCB BANK UGANDA LTD Oasis Mall Branch",
        value: "253747"
    },
    {
        name: "KCB BANK UGANDA LTD Sixth Street",
        value: "255247"
    },
    {
        name: "NATIONAL BANK OF COMMERCE Kabale Branch",
        value: "150161"
    },
    {
        name: "NATIONAL BANK OF COMMERCE Kampala Branch",
        value: "150147"
    },
    {
        name: "NC BANK UGANDA Head Office Rwenzori Towers",
        value: "350147"
    },
    {
        name: "OPPORTUNITY BANK UGNADA Head Office",
        value: "61001"
    },
    {
        name: "ORIENT BANK Entebbe Airport Branch",
        value: "110747"
    },
    {
        name: "ORIENT BANK Entebbe Town Branch",
        value: "110647"
    },
    {
        name: "ORIENT BANK Jinja Branch",
        value: "110241"
    },
    {
        name: "ORIENT BANK Kawempe Branch",
        value: "110847"
    },
    {
        name: "ORIENT BANK Main Branch",
        value: "110147"
    },
    {
        name: "ORIENT BANK Mbale Branch",
        value: "110942"
    },
    {
        name: "ORIENT BANK Nkrumah Road Branch",
        value: "110447"
    },
    {
        name: "ORIENT BANK William Street Branch",
        value: "110547"
    },
    {
        name: "POST BANK UGANDA Head Office",
        value: "56001"
    },
    {
        name: "PRIDE MICROFINANCE Head Office",
        value: "57001"
    },
    {
        name: "STANBIC BANK Stanbic Bank",
        value: "031085"
    },
    {
        name: "STANBIC BANK Adumani Branch",
        value: "46654"
    },
    {
        name: "STANBIC BANK Apac Branch",
        value: "46055"
    },
    {
        name: "STANBIC BANK Arua Branch",
        value: "46254"
    },
    {
        name: "STANBIC BANK Budibugyo Branch",
        value: "45560"
    },
    {
        name: "STANBIC BANK Buliisa",
        value: "47955"
    },
    {
        name: "STANBIC BANK Bushenyi Branch",
        value: "44162"
    },
    {
        name: "STANBIC BANK Busia Branch",
        value: "43042"
    },
    {
        name: "STANBIC BANK Bwamiramira",
        value: "45847"
    },
    {
        name: "STANBIC BANK City Branch",
        value: "40847"
    },
    {
        name: "STANBIC BANK Entebbe Airport",
        value: "41847"
    },
    {
        name: "STANBIC BANK Entebbe Main Branch",
        value: "41747"
    },
    {
        name: "STANBIC BANK Forest Mall",
        value: "47847"
    },
    {
        name: "STANBIC BANK Fortportal Branch",
        value: "45360"
    },
    {
        name: "STANBIC BANK Garden City",
        value: "40647"
    },
    {
        name: "STANBIC BANK Gulu Branch",
        value: "45955"
    },
    {
        name: "STANBIC BANK Head Office",
        value: "40047"
    },
    {
        name: "STANBIC BANK Hoima Branch",
        value: "45755"
    },
    {
        name: "STANBIC BANK IBC",
        value: "40147"
    },
    {
        name: "STANBIC BANK IPS Branch",
        value: "40447"
    },
    {
        name: "STANBIC BANK Ibanda Branch",
        value: "43962"
    },
    {
        name: "STANBIC BANK Iganga Branch",
        value: "42741"
    },
    {
        name: "STANBIC BANK Industrial Area Branch",
        value: "48347"
    },
    {
        name: "STANBIC BANK Ishaka Branch",
        value: "44062"
    },
    {
        name: "STANBIC BANK Jinja Main Branch",
        value: "42641"
    },
    {
        name: "STANBIC BANK Kabalagala Branch",
        value: "48247"
    },
    {
        name: "STANBIC BANK Kabale Branch",
        value: "44861"
    },
    {
        name: "STANBIC BANK Kabwohe Branch",
        value: "44362"
    },
    {
        name: "STANBIC BANK Kakira Branch",
        value: "47341"
    },
    {
        name: "STANBIC BANK Kalangala Branch",
        value: "45134"
    },
    {
        name: "STANBIC BANK Kampala Corporate Branch",
        value: "40247"
    },
    {
        name: "STANBIC BANK Kamuli Branch",
        value: "42841"
    },
    {
        name: "STANBIC BANK KamwNigeriae Branch",
        value: "48048"
    },
    {
        name: "STANBIC BANK Kapchorwa Branch",
        value: "43342"
    },
    {
        name: "STANBIC BANK Kasese Branch",
        value: "45460"
    },
    {
        name: "STANBIC BANK Katwe Branch",
        value: "41047"
    },
    {
        name: "STANBIC BANK Kiboga",
        value: "42547"
    },
    {
        name: "STANBIC BANK Kigumba Branch",
        value: "47055"
    },
    {
        name: "STANBIC BANK Kihihi Branch",
        value: "45061"
    },
    {
        name: "STANBIC BANK Kikuubo",
        value: "41147"
    },
    {
        name: "STANBIC BANK Kinyara Branch",
        value: "46855"
    },
    {
        name: "STANBIC BANK Kireka",
        value: "40947"
    },
    {
        name: "STANBIC BANK Kisoro Branch",
        value: "44761"
    },
    {
        name: "STANBIC BANK Kitgum Branch",
        value: "46755"
    },
    {
        name: "STANBIC BANK Koboko Branch",
        value: "47254"
    },
    {
        name: "STANBIC BANK Kotido Branch",
        value: "45242"
    },
    {
        name: "STANBIC BANK Kumi Branch",
        value: "43742"
    },
    {
        name: "STANBIC BANK Kyambogo",
        value: "41347"
    },
    {
        name: "STANBIC BANK Kyotera Branch",
        value: "44537"
    },
    {
        name: "STANBIC BANK Lira Branch",
        value: "46155"
    },
    {
        name: "STANBIC BANK Lugazi Branch",
        value: "42447"
    },
    {
        name: "STANBIC BANK Lugogo",
        value: "40347"
    },
    {
        name: "STANBIC BANK Luwero Branch",
        value: "42047"
    },
    {
        name: "STANBIC BANK Lyantonde Branch",
        value: "44662"
    },
    {
        name: "STANBIC BANK Makerere University Branch",
        value: "41647"
    },
    {
        name: "STANBIC BANK Malaba Branch",
        value: "43142"
    },
    {
        name: "STANBIC BANK Masaka Branch",
        value: "44434"
    },
    {
        name: "STANBIC BANK Masindi Branch",
        value: "45655"
    },
    {
        name: "STANBIC BANK Mbale Branch",
        value: "43242"
    },
    {
        name: "STANBIC BANK Mbarara Branch",
        value: "43862"
    },
    {
        name: "STANBIC BANK Mityana Branch",
        value: "42247"
    },
    {
        name: "STANBIC BANK Moroto Branch",
        value: "43542"
    },
    {
        name: "STANBIC BANK Moyo Branch",
        value: "46454"
    },
    {
        name: "STANBIC BANK Mpigi Branch",
        value: "42147"
    },
    {
        name: "STANBIC BANK Mubende Branch",
        value: "42347"
    },
    {
        name: "STANBIC BANK Mukono Branch",
        value: "41947"
    },
    {
        name: "STANBIC BANK Mulago Branch",
        value: "46947"
    },
    {
        name: "STANBIC BANK Mutukula",
        value: "47434"
    },
    {
        name: "STANBIC BANK Nakasero",
        value: "47647"
    },
    {
        name: "STANBIC BANK Nakivubo",
        value: "41447"
    },
    {
        name: "STANBIC BANK Nateete Branch",
        value: "48147"
    },
    {
        name: "STANBIC BANK Nebbi Branch",
        value: "46354"
    },
    {
        name: "STANBIC BANK Ntinda Branch",
        value: "47747"
    },
    {
        name: "STANBIC BANK Ntungamo Branch",
        value: "44262"
    },
    {
        name: "STANBIC BANK OPC",
        value: "40547"
    },
    {
        name: "STANBIC BANK Pader Branch",
        value: "40755"
    },
    {
        name: "STANBIC BANK Pakwach Branch",
        value: "46555"
    },
    {
        name: "STANBIC BANK Pallisa Branch",
        value: "43442"
    },
    {
        name: "STANBIC BANK Rukungiri Branch",
        value: "44961"
    },
    {
        name: "STANBIC BANK Soroti Branch",
        value: "43642"
    },
    {
        name: "STANBIC BANK Stanbic Bank Nakawa",
        value: "41247"
    },
    {
        name: "STANBIC BANK Tororo Branch",
        value: "42942"
    },
    {
        name: "STANBIC BANK Wandegeya",
        value: "41547"
    },
    {
        name: "STANBIC BANK William Street",
        value: "47547"
    },
    {
        name: "STANDARD CHARTERED BANK Garden City",
        value: "80847"
    },
    {
        name: "STANDARD CHARTERED BANK Gulu Branch",
        value: "81155"
    },
    {
        name: "STANDARD CHARTERED BANK Jinja Branch",
        value: "80641"
    },
    {
        name: "STANDARD CHARTERED BANK Kampala City Branch",
        value: "80347"
    },
    {
        name: "STANDARD CHARTERED BANK Kampala Head Office",
        value: "80147"
    },
    {
        name: "STANDARD CHARTERED BANK Kampala SpKenya Road Branch",
        value: "80247"
    },
    {
        name: "STANDARD CHARTERED BANK Kikuubo Branch",
        value: "80947"
    },
    {
        name: "STANDARD CHARTERED BANK Lugogo Branch",
        value: "80747"
    },
    {
        name: "STANDARD CHARTERED BANK Mbale Branch",
        value: "80562"
    },
    {
        name: "STANDARD CHARTERED BANK Mbarara Branch",
        value: "80442"
    },
    {
        name: "STANDARD CHARTERED BANK Ntinda Branch",
        value: "81047"
    },
    {
        name: "TROPICAL BANK OF AFRICA Jinja Branch",
        value: "60241"
    },
    {
        name: "TROPICAL BANK OF AFRICA Kampala Main",
        value: "60147"
    },
    {
        name: "TROPICAL BANK OF AFRICA Kansanga Branch",
        value: "60647"
    },
    {
        name: "TROPICAL BANK OF AFRICA Kawempe BranchÂ",
        value: "60747"
    },
    {
        name: "TROPICAL BANK OF AFRICA Masaka Branch",
        value: "60334"
    },
    {
        name: "TROPICAL BANK OF AFRICA Nakivubo Branch",
        value: "60547"
    },
    {
        name: "TROPICAL BANK OF AFRICA Ntinda Branch",
        value: "60447"
    },
    {
        name: "TROPICAL BANK OF AFRICA Oasis Mall Branch",
        value: "60847"
    },
    {
        name: "UBA BANK Fortportal",
        value: "260960"
    },
    {
        name: "UBA BANK Jinja Branch",
        value: "260641"
    },
    {
        name: "UBA BANK Jinja Road Branch",
        value: "260147"
    },
    {
        name: "UBA BANK Kansanga Branch",
        value: "260547"
    },
    {
        name: "UBA BANK Masaka road Branch",
        value: "260447"
    },
    {
        name: "UBA BANK Mbale Branch",
        value: "260742"
    },
    {
        name: "UBA BANK Mbarara Branch",
        value: "260862"
    },
    {
        name: "UBA BANK Mukono Branch",
        value: "260347"
    },
    {
        name: "UBA BANK William Street Branch",
        value: "260247"
    },
]

export const transferReasonUgandaUGXBank = [
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
    {
        name: "",
        value: ""
    },
]

export const xofCashCountries = [
    {
        name: "Ivory Coast",
        value: "CI"
    },
    {
        name: "Senegal",
        value: "SN"
    }

]

export const egyptUsdBankCountries = [
    {
        name: "Egypt",
        value: "EG"
    },
]

export const xofMobileCountries = [
    {
        name: "Ivory Coast",
        value: "CI"
    },
    {
        name: "Senegal",
        value: "SN"
    },
    {
        name: "Benin",
        value: "BJ"
    },
    {
        name: "Burkina Faso",
        value: "BF"
    },
    {
        name: "Togo",
        value: "TG"
    },

]

export const xafMobileCountries = [
    {
        name: "Cameroon",
        value: "CM"
    },
]

export const mobileProvidersGhana = [
    {
        name: "AirtelTigo",
        value: "airteltigo"
    },
    {
        name: "MTN",
        value: "mtn"
    },
    {
        name: "Vodafone",
        value: "vodafone"
    },

]

export const mobileProvidersUganda = [
    {
        name: "Airtel",
        value: "airtel"
    },
    {
        name: "MTN",
        value: "mtn"
    },

]

export const mobileProvidersKenya = [
    {
        name: "MPesa",
        value: "mpesa"
    }

]

export const transferReasons = [
    {
        name: "Sending money into my own account",
        value: "personal_account"
    },
    {
        name: "Donations and gifts",
        value: "donations_and_gifts"
    },
    {
        name: "Sending money to a friend, family member, or any third party person",
        value: "third_party_person_account"
    },
    {
        name: "Mortgage repayments",
        value: "mortgage_repayments"
    },
    {
        name: "Business Travel Payments",
        value: "business_travel_payments"
    },
    {
        name: "Personal Travel Payments",
        value: "personal_travel_payments"
    },
    {
        name: "Tuition fees",
        value: "tuition_fees"
    },
    {
        name: "Commission and fees for financial services",
        value: "financial_commission_fees"
    },
    {
        name: "Proceeds for financial services charged for advice provided",
        value: "financial_services_proceeds"
    },
    {
        name: "Investment into property by a foreign individual",
        value: "individual_property_investments"
    },
    {
        name: "Investment by a foreign individual - other",
        value: "other_investments"
    },
    {
        name: "Investment into property by a foreign corporate entity",
        value: "corporate_property_investments"
    },
    {
        name: "Capital payments by immigrants",
        value: "immigrants_capital_payments"
    },
    {
        name: "Legal services",
        value: "legal_services"
    },
    {
        name: "Accounting services",
        value: "accounting_services"
    },
    {
        name: "Management consulting services",
        value: "consulting_services"
    },
    {
        name: "Public relation services",
        value: "public_relation_services"
    },
    {
        name: "Advertising & market research services",
        value: "ads_and_market_research_services"
    },
    {
        name: "Managerial services",
        value: "managerial_services"
    },
    {
        name: "Medical and dental services",
        value: "medical_and_dental_services"
    },
    {
        name: "Educational services",
        value: "educational_services"
    },
    {
        name: "Operational leasing",
        value: "operational_leasing"
    },
    {
        name: "Cultural and recreational services",
        value: "cultural_and_recreational_services"
    },
    {
        name: "Salary paid to Recipient Country Resident Temporarily Abroad",
        value: "salary_resident_out_country"
    },
    {
        name: "Salary paid to a non-resident employee in recipient country",
        value: "salary_non_resident_in_country"
    },
    {
        name: "Salary paid to a foreign national contract worker in recipient country",
        value: "salary_contract_worker_in_country"
    },
    {
        name: "Payments to social security schemes",
        value: "social_security_schemes"
    },
    {
        name: "Payments to charities and religious bodies",
        value: "charities_and_religious_bodies"
    },
    {
        name: "Donations and aid to Government",
        value: "donations_and_aid_to_government"
    },
    {
        name: "Donations and aid to the private sector",
        value: "donations_and_aid_to_private"
    },
    {
        name: "Pensions",
        value: "pensions"
    },
    {
        name: "Annuities",
        value: "annuities"
    },
    {
        name: "Inheritances",
        value: "inheritances"
    },
    {
        name: "Alimony",
        value: "alimony"
    },
    {
        name: "Tax - Income tax",
        value: "tax_income"
    },
    {
        name: "Tax - VAT refunds",
        value: "tax_vat_refunds"
    },
    {
        name: "Tax - Other",
        value: "tax_other"
    },
    {
        name: "Insurance premiums (non-life/short term)",
        value: "non_life_insurance_premiums"
    },
    {
        name: "Insurance premiums (life)",
        value: "life_insurance_premiums"
    },
    {
        name: "Dividends",
        value: "dividends"
    },
    {
        name: "Branch profits",
        value: "branch_profits"
    },
    {
        name: "Commission or brokerage",
        value: "commission_or_brokerage"
    },
    {
        name: "Rental",
        value: "rental"
    },
    {
        name: "Income earned abroad by a resident on an individual investment",
        value: "individual_investment_income_from_abroad"
    },
    {
        name: "Sale of shares",
        value: "shares_sale"
    },
    {
        name: "Sale of bonds",
        value: "bonds_sale"
    },
    {
        name: "Sale of money market instruments",
        value: "money_market_instruments_sale"
    },
    {
        name: "Repatriation of funds out of a foreign bank account",
        value: "funds_repatriation_of_foreign_bank_account"
    },
    {
        name: "Sale of mutual funds or collective investment schemes",
        value: "mutual_funds_sale_or_collective_investment"
    },
    {
        name: "Sale of overseas property",
        value: "overseas_property_sale"
    },
    {
        name: "Sale and repatriation of other investment",
        value: "sale_and_repatriation_of_other_investment"
    },
    {
        name: "Repatriation on instruction by the Central Bank",
        value: "repatriation_south_african_reserve_bank_instruction"
    },
    {
        name: "Loan made to a resident by a non-resident shareholder",
        value: "resident_loan_from_non_resident_shareholder"
    },
    {
        name: "Loan made to a resident by a non-resident third party",
        value: "resident_loan_from_non_resident_third_party"
    },
    {
        name: "Repayment by a Citizen living overseas of a loan granted by a resident",
        value: "resident_loan_repayment_from_overseas_living_south_african"
    },
    {
        name: "Repayment of a study loan",
        value: "study_loan_repayment"
    },
    {
        name: "Repayment of a shareholder's loan",
        value: "shareholders_loan_repayment"
    },
    {
        name: "Repayment of a third-party loan (excluding shareholders)",
        value: "third_party_loan_repayment"
    },
    {
        name: "Repayment of a trade finance loan",
        value: "trade_finance_loan_repayment"
    },
    {
        name: "Proceeds received for research and development services",
        value: "research_and_development_proceeds"
    },
    {
        name: "Funding received for research and development",
        value: "research_and_development_funding"
    },
    {
        name: "Repairs and maintenance on machinery and equipment",
        value: "machinery_and_equipment_repairs"
    },
    {
        name: "Architectural, engineering, and other technical services",
        value: "architectural_engineering_technical_services"
    },
    {
        name: "Agricultural, mining, waste treatment, and depollution services",
        value: "agricultural_mining_waste_depollution_services"
    },
    {
        name: "Proceeds for construction services",
        value: "construction_services"
    },
    {
        name: "Payments for telecommunication services",
        value: "telecommunication_services"
    },
    {
        name: "Payments for data, news related, and news agency fees",
        value: "data_news_agency_fees"
    },
    {
        name: "Payments for passenger services - road",
        value: "road_passenger_services"
    },
    {
        name: "Payments for passenger services - rail",
        value: "rail_passenger_services"
    },
    {
        name: "Payments for passenger services - sea",
        value: "sea_passenger_services"
    },
    {
        name: "Payments for passenger services - air",
        value: "air_passenger_services"
    },
    {
        name: "Payments for freight services - road",
        value: "road_freight_services"
    },
    {
        name: "Payments for freight services - rail",
        value: "rail_freight_services"
    },
    {
        name: "Payments for freight services - sea",
        value: "sea_freight_services"
    },
    {
        name: "Payments for freight services - air",
        value: "air_freight_services"
    },
    {
        name: "Payments for postal and courier services - road",
        value: "road_postal_and_courier_services"
    },
    {
        name: "Payments for postal and courier services - rail",
        value: "rail_postal_and_courier_services"
    },
    {
        name: "Payments for postal and courier services - sea",
        value: "sea_postal_and_courier_services"
    },
    {
        name: "Payments for postal and courier services - air",
        value: "air_postal_and_courier_services"
    },
    {
        name: "Investment in listed shares",
        value: "listed_shares_investment"
    },
    {
        name: "Investment in non-listed shares",
        value: "non_listed_shares_investment"
    },
    {
        name: "Investment into money market instruments",
        value: "money_market_instruments_investment"
    },
    {
        name: "Investment into listed bonds",
        value: "listed_bonds_investment"
    },
    {
        name: "Investment into non-listed bonds",
        value: "non_listed_bonds_investment"
    },
    {
        name: "Rights assigned for licenses to reproduce and/or distribute",
        value: "reproduce_distribute_rights_license"
    },
    {
        name: "Rights assigned for using patents and inventions (licensing)",
        value: "patents_and_inventions_rights_license"
    },
    {
        name: "Rights assigned for using patterns and designs (including industrial processes)",
        value: "patterns_and_designs_rights"
    },
    {
        name: "Rights assigned for using copyrights",
        value: "copyrights_rights"
    },
    {
        name: "Rights assigned for using franchises and trademarks",
        value: "franchises_and_trademarks_rights"
    },
    {
        name: "Disposal of patents and inventions",
        value: "patents_and_inventions_disposal"
    },
    {
        name: "Disposal of patterns and designs (including industrial processes)",
        value: "patterns_and_designs_disposal"
    },
    {
        name: "Disposal of copyrights",
        value: "copyrights_disposal"
    },
    {
        name: "Disposal of franchises and trademarks",
        value: "franchises_and_trademarks_disposal"
    },
    {
        name: "Sales of original manuscripts, sound recordings, and films",
        value: "sales_of_manuscripts_sound_recordings_films"
    },
    {
        name: "Receipt of funds relating to the production of motion pictures, radio and television programs and musical recordings",
        value: "funds_related_to_recording_productions"
    },
    {
        name: "The outright selling of ownership rights of software",
        value: "software_ownership_rights_sale"
    },
    {
        name: "Computer-related services including maintenance, repair, and consultancy",
        value: "computer_services"
    },
    {
        name: "Commercial sales of customized software and related licenses for use of customers",
        value: "customized_software_sales"
    },
    {
        name: "Commercial sales of non-customized software on physical media with periodic license to use",
        value: "non_customized_software_on_physical_media_periodic_license_sale"
    },
    {
        name: "Commercial sales of non-customized software provided on physical media with right to perpetual (ongoing) use",
        value: "non_customized_software_on_physical_media_perpetual_use_sale"
    },
    {
        name: "Commercial sales of non-customized software provided for downloading or electronically made periodic license",
        value: "non_customized_software_for_downloading_electronically_made_periodic_license_sale"
    },
    {
        name: "Commercial sales of non-customized software provided for downloading or electronically made single payment",
        value: "non_customized_software_for_downloading_electronically_made_single_payment_sale"
    },
    {
        name: "Donations to Government for fixed assets",
        value: "fixed_assets_donations_to_sa_government"
    },
    {
        name: "Donations to corporate entities - fixed assets",
        value: "fixed_assets_donations_to_corporate_entities"
    },
    {
        name: "Disinvestment of property by a resident corporate entity",
        value: "property_disinvestment_by_corporate_entity_resident"
    },
    {
        name: "Proceeds for other business services not included elsewhere",
        value: "other_business_services"
    },
    {
        name: "Disinvestment by resident institutional investor - Collective Investment Scheme",
        value: "institutional_investor_disinvestment"
    },
    {
        name: "Payment for Government Services",
        value: "government_services" }

]

export const bankAccountTypesUS = [
    {
        name: "Savings",
        value: "10"
    },
    {
        name: "Checking",
        value: "20"
    }

]

export const idTypesMorocco = [
    {
        name: "National ID",
        value: "ID"
    },
    {
        name: "Other",
        value: "OT"
    },
    {
        name: "Passport",
        value: "PP"
    },

]

export const mobileProvidersXOFCash = [
    {
        name: "Airtel",
        value: "airtel"
    },
    {
        name: "MTN",
        value: "mtn"
    },

]

export const mobileProvidersBeninXOF = [
    {
        name: "Moov",
        value: "moov"
    },
    {
        name: "MTN",
        value: "mtn"
    },

]

export const mobileProvidersBFXOF = [
    {
        name: "Moov",
        value: "moov"
    },
    {
        name: "Orange",
        value: "orange"
    },

]

export const mobileProvidersIvoryCoastXOF = [
    {
        name: "Moov",
        value: "moov"
    },
    {
        name: "MTN",
        value: "mtn"
    },
    {
        name: "Orange",
        value: "orange"
    },

]

export const mobileProvidersSenegalXOF = [
    {
        name: "Expresso",
        value: "expresso"
    },
    {
        name: "Free",
        value: "free"
    },
    {
        name: "Orange",
        value: "orange"
    },
    {
        name: "Wave",
        value: "wave"
    },

]

export const mobileProvidersTogoXOF = [
    {
        name: "Moov",
        value: "moov"
    },
    {
        name: "TMoney",
        value: "tmoney"
    },

]

export const mobileProvidersXAF = [
    {
        name: "MTN",
        value: "mtn"
    },
    {
        name: "Orange",
        value: "orange"
    },
]

export const mobileProvidersGuinea = [
    {
        name: "MTN",
        value: "mtn"
    },
    {
        name: "Orange",
        value: "orange"
    },

]

export const pixKeyTypesBrazil = [
    {
        name: "CNPJ",
        value: "cnpj"
    },
    {
        name: "CPF",
        value: "cpf"
    },
    {
        name: "Email",
        value: "email"
    },
    {
        name: "EVP",
        value: "evp"
    },
    {
        name: "Phone",
        value: "phone"
    },

]

export const bankAccountTypesTED = [
    {
        name: "Savings",
        value: "10"
    },
    {
        name: "Current",
        value: "20"
    },
    {
        name: "Payment",
        value: "30"
    },
    {
        name: "Salary",
        value: "40"
    }

]

export const idTypesKenya = [
    {
        name: "Driver's License",
        value: "DL"
    },
    {
        name: "National ID",
        value: "ID"
    },
    {
        name: "Other",
        value: "OT"
    },
    {
        name: "Passport",
        value: "PP"
    }

]

export const branchCodeListZMWBank = [
    {
        name: "AB Bank Zambia Limited, AB Bank Zambia Limited Matero",
        value: "ZM210003"
    },
    {
        name: "AB Bank Zambia Limited, Chelston Branch",
        value: "ZM210005"
    },
    {
        name: "AB Bank Zambia Limited, Chilenje Branch",
        value: "ZM210002"
    },
    {
        name: "AB Bank Zambia Limited, Garden Branch",
        value: "ZM210006"
    },
    {
        name: "AB Bank Zambia Limited, Head Office",
        value: "ZM210000"
    },
    {
        name: "AB Bank Zambia Limited, Kalingalinga Branch",
        value: "ZM210004"
    },
    {
        name: "AB Bank Zambia Limited, KITWE",
        value: "ZM210207"
    },
    {
        name: "AB Bank Zambia Limited, Main Cairo Road Branch",
        value: "ZM210001"
    },
    {
        name: "ABSA Bank, Livingstone, Zambia",
        value: "ZM21012"
    },
    {
        name: "Access Bank, ACACIA BRANCH",
        value: "ZM350003"
    },
    {
        name: "Access Bank, CAIRO ROAD",
        value: "ZM350001"
    },
    {
        name: "Access Bank, CAROUSEL CASH CENTRE",
        value: "ZM350006"
    },
    {
        name: "Access Bank, CHILILABOMBWE BRANCH",
        value: "ZM350409"
    },
    {
        name: "Access Bank, CHINGOLA BRANCH",
        value: "ZM350310"
    },
    {
        name: "Access Bank, CHIPATA BRANCH",
        value: "ZM351611"
    },
    {
        name: "Access Bank, DANGOTE AGENCY",
        value: "ZM350104"
    },
    {
        name: "Access Bank, GARDEN BRANCH",
        value: "ZM350012"
    },
    {
        name: "Access Bank, HEAD OFFICE",
        value: "ZM350000"
    },
    {
        name: "Access Bank, KALIINGALINGA BRANCH",
        value: "ZM350013"
    },
    {
        name: "Access Bank, KASAMA BRANCH",
        value: "ZM350814"
    },
    {
        name: "Access Bank, KITWE BRANCH",
        value: "ZM350205"
    },
    {
        name: "Access Bank, LONGACRES BRANCH",
        value: "ZM350002"
    },
    {
        name: "Access Bank, LUSAKA SQUARE",
        value: "ZM350008"
    },
    {
        name: "Access Bank, MAKENI MALL BRANCH",
        value: "ZM350006"
    },
    {
        name: "Access Bank, MANSA BRANCH",
        value: "ZM351915"
    },
    {
        name: "Access Bank, MBALA BRANCH",
        value: "ZM351416"
    },
    {
        name: "Access Bank, MUFUMBWE BRANCH",
        value: "ZM352817"
    },
    {
        name: "Access Bank, NDOLA BRANCH",
        value: "ZM350104"
    },
    {
        name: "Access Bank, SOLWEZI BRANCH",
        value: "ZM352807"
    },
    {
        name: "Access Bank, TAZARA BRANCH",
        value: "ZM350018"
    },
    {
        name: "Atlas Mara, Arcades",
        value: "ZM200009"
    },
    {
        name: "Atlas Mara, Chililabombwe",
        value: "ZM200443"
    },
    {
        name: "Atlas Mara, Chingola",
        value: "ZM200315"
    },
    {
        name: "Atlas Mara, Chinsali",
        value: "ZM201728"
    },
    {
        name: "Atlas Mara, Chipata",
        value: "ZM201108"
    },
    {
        name: "Atlas Mara, Chirundu",
        value: "ZM203514"
    },
    {
        name: "Atlas Mara, Choma",
        value: "ZM201205"
    },
    {
        name: "Atlas Mara, Chongwe",
        value: "ZM204638"
    },
    {
        name: "Atlas Mara, Down Town",
        value: "ZM200032"
    },
    {
        name: "Atlas Mara, East Park Mall",
        value: "ZM200050"
    },
    {
        name: "Atlas Mara, Head Office",
        value: "ZM200000"
    },
    {
        name: "Atlas Mara, Industrial",
        value: "ZM200033"
    },
    {
        name: "Atlas Mara, Isoka",
        value: "ZM201627"
    },
    {
        name: "Atlas Mara, Kabompo",
        value: "ZM202919"
    },
    {
        name: "Atlas Mara, Kabwe",
        value: "ZM200925"
    },
    {
        name: "Atlas Mara, Kafubu Mall",
        value: "ZM200149"
    },
    {
        name: "Atlas Mara, Kalomo",
        value: "ZM204110"
    },
    {
        name: "Atlas Mara, Kamwala",
        value: "ZM200007"
    },
    {
        name: "Atlas Mara, Kaoma",
        value: "ZM204430"
    },
    {
        name: "Atlas Mara, Kasama",
        value: "ZM200804"
    },
    {
        name: "Atlas Mara, Kasumbalesa",
        value: "ZM200434"
    },
    {
        name: "Atlas Mara, Katete",
        value: "ZM201116"
    },
    {
        name: "Atlas Mara, Kitwe",
        value: "ZM200203"
    },
    {
        name: "Atlas Mara, Livingstone",
        value: "ZM201023"
    },
    {
        name: "Atlas Mara, Longacres",
        value: "ZM200006"
    },
    {
        name: "Atlas Mara, Luanshya",
        value: "ZM200731"
    },
    {
        name: "Atlas Mara, Lundazi",
        value: "ZM202541"
    },
    {
        name: "Atlas Mara, Lusaka Main",
        value: "ZM200001"
    },
    {
        name: "Atlas Mara, Mansa Branch",
        value: "ZM201942"
    },
    {
        name: "Atlas Mara, Mbala",
        value: "ZM201421"
    },
    {
        name: "Atlas Mara, Mongu",
        value: "ZM203145"
    },
    {
        name: "Atlas Mara, Monze",
        value: "ZM203724"
    },
    {
        name: "Atlas Mara, Mpika",
        value: "ZM201826"
    },
    {
        name: "Atlas Mara, Mpulungu",
        value: "ZM201329"
    },
    {
        name: "Atlas Mara, Mufulira",
        value: "ZM200513"
    },
    {
        name: "Atlas Mara, Mukuba Mall",
        value: "ZM200251"
    },
    {
        name: "Atlas Mara, Mumbwa",
        value: "ZM205339"
    },
    {
        name: "Atlas Mara, Mwinilunga",
        value: "ZM202718"
    },
    {
        name: "Atlas Mara, Nakonde",
        value: "ZM201520"
    },
    {
        name: "Atlas Mara, Ndola",
        value: "ZM200102"
    },
    {
        name: "Atlas Mara, Nyumba Yanga",
        value: "ZM200036"
    },
    {
        name: "Atlas Mara, Pyramid Plaza",
        value: "ZM200044"
    },
    {
        name: "Atlas Mara, Samfya",
        value: "ZM202012"
    },
    {
        name: "Atlas Mara, Serenje",
        value: "ZM202211"
    },
    {
        name: "Atlas Mara, Sesheke",
        value: "ZM203217"
    },
    {
        name: "Atlas Mara, Sinazeze",
        value: "ZM201247"
    },
    {
        name: "Atlas Mara, Solwezi",
        value: "ZM202822"
    },
    {
        name: "Atlas Mara, UTH",
        value: "ZM200052"
    },
    {
        name: "Bank of China, Bank Of China (Zambia) lusaka",
        value: "ZM190001"
    },
    {
        name: "Bank of China, Bank of China KITWE",
        value: "ZM190202"
    },
    {
        name: "Citibank, Citibank Natsave",
        value: "ZM030007"
    },
    {
        name: "Citibank, Citibank Zambia Lusaka",
        value: "ZM030001"
    },
    {
        name: "Citibank, Citibank Zambia Mcommerce Branch",
        value: "ZM030003"
    },
    {
        name: "Citibank, Citibank Zambia Ndola",
        value: "ZM030102"
    },
    {
        name: "Citibank, Citibank ZNBS Branch",
        value: "ZM030008"
    },
    {
        name: "Ecobank, Cairo road",
        value: "ZM360002"
    },
    {
        name: "Ecobank, Chibombo",
        value: "ZM365506"
    },
    {
        name: "Ecobank, Copperbelt University",
        value: "ZM360208"
    },
    {
        name: "Ecobank, Ecobank Ndola Branch",
        value: "ZM360109"
    },
    {
        name: "Ecobank, ECOBANK ZAMBIA",
        value: "ZMNG001"
    },
    {
        name: "Ecobank, Ecobank Zambia Ltd Kitwe",
        value: "ZM360205"
    },
    {
        name: "Ecobank, Industrial Branch",
        value: "ZM360007"
    },
    {
        name: "Ecobank, Kitwe",
        value: "ZM360004"
    },
    {
        name: "Ecobank, Lumumba Branch",
        value: "ZM360010"
    },
    {
        name: "Ecobank, Mazabuka Branch",
        value: "ZM363611"
    },
    {
        name: "Ecobank, System Branch-Head Office",
        value: "ZM360000"
    },
    {
        name: "Ecobank, Thabo Mbeki",
        value: "ZM360001"
    },
    {
        name: "Ecobank, Woodlands",
        value: "ZM360003"
    },
    {
        name: "First Alliance Bank, East Park Branch",
        value: "ZM340007"
    },
    {
        name: "First Alliance Bank, First Alliance Bank Kitwe",
        value: "ZM340204"
    },
    {
        name: "First Alliance Bank, First Alliance Bank Lusaka H O",
        value: "ZM340005"
    },
    {
        name: "First Alliance Bank, First Alliance Bank Northend",
        value: "ZM340103"
    },
    {
        name: "First Alliance Bank, First Alliance Bank Zambia H O",
        value: "ZM340001"
    },
    {
        name: "First Alliance Bank, First Alliance Bank Zambia Industrial Br",
        value: "ZM340006"
    },
    {
        name: "First Capital Bank, First Capital Bank",
        value: "ZM280000"
    },
    {
        name: "First Capital Bank, First Capital Bank",
        value: "ZM280002"
    },
    {
        name: "First Capital Bank, First Capitalbank Zambia Limited",
        value: "ZM280003"
    },
    {
        name: "First Capital Bank, First Capitalbank Zambia Kamwala Branch",
        value: "ZM280006"
    },
    {
        name: "First Capital Bank, Makeni Branch",
        value: "ZM280004"
    },
    {
        name: "First Capital Bank, Ndola Branch",
        value: "ZM280105"
    },
    {
        name: "First National Bank, Agriculture Center",
        value: "ZM260040"
    },
    {
        name: "First National Bank, Cash centre",
        value: "ZM260048"
    },
    {
        name: "First National Bank, Chilenje Branch",
        value: "ZM260046"
    },
    {
        name: "First National Bank, Chingola",
        value: "ZM260322"
    },
    {
        name: "First National Bank, Choma Branch",
        value: "ZM261238"
    },
    {
        name: "First National Bank, Corporate Investment Banking",
        value: "ZM260042"
    },
    {
        name: "First National Bank, First National Bank Comm Suite Lusaka",
        value: "ZM260001"
    },
    {
        name: "First National Bank, First National Bank Electronic Banking",
        value: "ZM260006"
    },
    {
        name: "First National Bank, First National Bank H O Branch",
        value: "ZM260005"
    },
    {
        name: "First National Bank, First National Bank Industrial Branch",
        value: "ZM260002"
    },
    {
        name: "First National Bank, First National Bank Kitwe",
        value: "ZM260212"
    },
    {
        name: "First National Bank, First National Bank Makeni Mall",
        value: "ZM260016"
    },
    {
        name: "First National Bank, First National Bank Manda Hill",
        value: "ZM260014"
    },
    {
        name: "First National Bank, First National Bank Mazabuka",
        value: "ZM263613"
    },
    {
        name: "First National Bank, First National Bank Ndola Branch",
        value: "ZM260104"
    },
    {
        name: "First National Bank, First National Bank Ndola Branch",
        value: "ZM260103"
    },
    {
        name: "First National Bank, FNB  Kabulonga Branch",
        value: "ZM260072"
    },
    {
        name: "First National Bank, FNB - BranchlSouth Sudan Banking",
        value: "ZM260025"
    },
    {
        name: "First National Bank, FNB - CIB (Corporate)",
        value: "ZM260029"
    },
    {
        name: "First National Bank, FNB - Government and Public Sector",
        value: "ZM260036"
    },
    {
        name: "First National Bank, FNB Cairo Road Branch",
        value: "ZM260050"
    },
    {
        name: "First National Bank, FNB Livingstone Branch",
        value: "ZM261061"
    },
    {
        name: "First National Bank, FNB Operations",
        value: "ZM260004"
    },
    {
        name: "First National Bank, Homes Loans",
        value: "ZM260020"
    },
    {
        name: "First National Bank, Jacaranda Mall",
        value: "ZM260118"
    },
    {
        name: "First National Bank, Kabwe Branch",
        value: "ZM260937"
    },
    {
        name: "First National Bank, Kitwe Industrial",
        value: "ZM260247"
    },
    {
        name: "First National Bank, Luanshya",
        value: "ZM260741"
    },
    {
        name: "First National Bank, Mkushi",
        value: "ZM262319"
    },
    {
        name: "First National Bank, Mufulira Branch",
        value: "ZM260544"
    },
    {
        name: "First National Bank, Mukuba Mall Branch",
        value: "ZM260243"
    },
    {
        name: "First National Bank, PHI Branch",
        value: "ZM260049"
    },
    {
        name: "First National Bank, POS – FNB",
        value: "ZM260033"
    },
    {
        name: "First National Bank, POS-Visa",
        value: "ZM260031"
    },
    {
        name: "First National Bank, Premier Banking - Lusaka",
        value: "ZM260039"
    },
    {
        name: "First National Bank, Solwezi",
        value: "ZM262823"
    },
    {
        name: "First National Bank, Treasury Branch",
        value: "ZM260011"
    },
    {
        name: "First National Bank, Vehicle and Asset Finance",
        value: "ZM260015"
    },
    {
        name: "Indo Zambia Bank, Chilenje Branch",
        value: "ZM090028"
    },
    {
        name: "Indo Zambia Bank, Chinsali",
        value: "ZM091721"
    },
    {
        name: "Indo Zambia Bank, Choma Branch",
        value: "ZM091218"
    },
    {
        name: "Indo Zambia Bank, Copperhill",
        value: "ZM090225"
    },
    {
        name: "Indo Zambia Bank, Cross Roads Shopping Mall",
        value: "ZM090023"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Chandwe Musonda",
        value: "ZM090016"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Chawama",
        value: "ZM090013"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Chilanga",
        value: "ZM090003"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Chingola",
        value: "ZM090309"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Chipata",
        value: "ZM091112"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank H O",
        value: "ZM090000"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Kabwe",
        value: "ZM090906"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Kamwala",
        value: "ZM090004"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Kitwe",
        value: "ZM090208"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Livingstone",
        value: "ZM091010"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Ltd Kasama",
        value: "ZM090820"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Ltd Solwezi",
        value: "ZM092819"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Lusaka Industrial",
        value: "ZM090011"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Lusaka Main",
        value: "ZM090001"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Manda Hill",
        value: "ZM090014"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Ndola",
        value: "ZM090107"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank North end",
        value: "ZM090005"
    },
    {
        name: "Indo Zambia Bank, Indo Zambia Bank Nyimba Branch",
        value: "ZM095415"
    },
    {
        name: "Indo Zambia Bank, Jacaranda Mall",
        value: "ZM090122"
    },
    {
        name: "Indo Zambia Bank, Kafue Branch",
        value: "ZM093427"
    },
    {
        name: "Indo Zambia Bank, Kasumbalesa Branch",
        value: "ZM090417"
    },
    {
        name: "Indo Zambia Bank, Lundazi Agency",
        value: "ZM092531"
    },
    {
        name: "Indo Zambia Bank, Mansa Branch",
        value: "ZM091924"
    },
    {
        name: "Indo Zambia Bank, MONGU BRANCH",
        value: "ZM093126"
    },
    {
        name: "Indo Zambia Bank, Mungwi Agency",
        value: "ZM095632"
    },
    {
        name: "Indo Zambia Bank, Serenje Branch",
        value: "ZM092230"
    },
    {
        name: "Indo Zambia Bank, Zimba Branch",
        value: "ZM094229"
    },
    {
        name: "Investrust, Buteko Branch",
        value: "ZM170123"
    },
    {
        name: "Investrust, Chingola Branch",
        value: "ZM170326"
    },
    {
        name: "Investrust, Choma branch",
        value: "ZM171220"
    },
    {
        name: "Investrust, Copperbelt - CBU Branch",
        value: "ZM170227"
    },
    {
        name: "Investrust, Investrust Bank Chirundu Branch",
        value: "ZM173515"
    },
    {
        name: "Investrust, Investrust Bank Head Office Branch",
        value: "ZM170099"
    },
    {
        name: "Investrust, Investrust Bank Lusaka Industrial Br",
        value: "ZM170012"
    },
    {
        name: "Investrust, Investrust Bank Plc Arcades",
        value: "ZM170005"
    },
    {
        name: "Investrust, Investrust Bank Plc Chililabombwe",
        value: "ZM170407"
    },
    {
        name: "Investrust, Investrust Bank Plc Chipata",
        value: "ZM171103"
    },
    {
        name: "Investrust, Investrust Bank Plc Chirundu",
        value: "ZM173514"
    },
    {
        name: "Investrust, Investrust Bank Plc Kafue Road",
        value: "ZM170014"
    },
    {
        name: "Investrust, Investrust Bank Plc Kitwe",
        value: "ZM170202"
    },
    {
        name: "Investrust, Investrust Bank Plc Livingstone",
        value: "ZM171010"
    },
    {
        name: "Investrust, Investrust Bank Plc Luangwa",
        value: "ZM171104"
    },
    {
        name: "Investrust, Investrust Bank Plc Lumwana",
        value: "ZM174809"
    },
    {
        name: "Investrust, Investrust Bank Plc Lusaka",
        value: "ZM170001"
    },
    {
        name: "Investrust, Investrust Bank Plc Odys Branch",
        value: "ZM170011"
    },
    {
        name: "Investrust, Investrust Bank Plc Savings Center",
        value: "ZM170006"
    },
    {
        name: "Investrust, Investrust Bank Plc Solwezi",
        value: "ZM172808"
    },
    {
        name: "Investrust, Investrust Bank Plc VSTRZMLU Kabwe",
        value: "ZM170919"
    },
    {
        name: "Investrust, Investrust Bank Soweto Branch",
        value: "ZM170013"
    },
    {
        name: "Investrust, Kitwe Freedom Avenue Branch",
        value: "ZM170225"
    },
    {
        name: "Investrust, Levy Business South Sudan Branch",
        value: "ZM170018"
    },
    {
        name: "Investrust, Manda Hill Branch",
        value: "ZM170016"
    },
    {
        name: "Investrust, Mongu Branch",
        value: "ZM173124"
    },
    {
        name: "Investrust, Mulungushi Branch",
        value: "ZM170017"
    },
    {
        name: "Investrust, Mumbwa Road Branch",
        value: "ZM170022"
    },
    {
        name: "Investrust, President Avenue",
        value: "ZM170121"
    },
    {
        name: "Stanbic Bank, Cosmopolitan Mall",
        value: "ZM040093"
    },
    {
        name: "Stanbic Bank, East Park Mall",
        value: "ZM040094"
    },
    {
        name: "Stanbic Bank, Soweto",
        value: "ZM040023"
    },
    {
        name: "Stanbic Bank, Stanbic bank Kabulonga",
        value: "ZM040029"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Kafubu Mall",
        value: "ZM040195"
    },
    {
        name: "Stanbic Bank, Stanbic bank Mukuba Mall",
        value: "ZM040296"
    },
    {
        name: "Stanbic Bank, Stanbic bank Woodlands",
        value: "ZM040030"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Arcades",
        value: "ZM040010"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Chingola",
        value: "ZM040309"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Chipata",
        value: "ZM041116"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Choma Branch",
        value: "ZM041218"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia H O",
        value: "ZM040000"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Kabwe Branch",
        value: "ZM040922"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Kitwe",
        value: "ZM040206"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Livingstone",
        value: "ZM041017"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Ltd Soweto",
        value: "ZM045225"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Ltd Soweto",
        value: "ZM040224"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Lumwana Branch",
        value: "ZM044821"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Lusaka",
        value: "ZM040002"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Lusaka Industrial",
        value: "ZM040007"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Matero",
        value: "ZM040011"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Mazabuka",
        value: "ZM043613"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Mkushi",
        value: "ZM042308"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Mufulira",
        value: "ZM040514"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Mulungushi",
        value: "ZM040015"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Ndola Main",
        value: "ZM040103"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Ndola South",
        value: "ZM040105"
    },
    {
        name: "Stanbic Bank, Stanbic Bank Zambia Solwezi",
        value: "ZM042812"
    },
    {
        name: "Stanbic Bank, Stanbic Private Banking",
        value: "ZM040027"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Bank Levy Park",
        value: "ZM060021"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Buteko",
        value: "ZM060171"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Chililabombwe",
        value: "ZM060444"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Chingola",
        value: "ZM060336"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Choma",
        value: "ZM061237"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Cross Roads",
        value: "ZM060015"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Cust Services Centre",
        value: "ZM060002"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Financial Control",
        value: "ZM060011"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Kabulonga",
        value: "ZM060014"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Kasama",
        value: "ZM060813"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Livingstone",
        value: "ZM061018"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Luanshya",
        value: "ZM060732"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Lusaka Main",
        value: "ZM060017"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Manda Hill",
        value: "ZM060030"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Mazabuka",
        value: "ZM063619"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Mongu",
        value: "ZM063148"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Ndola South",
        value: "ZM060120"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered North end",
        value: "ZM060043"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Solwezi",
        value: "ZM062816"
    },
    {
        name: "Standard Chartered Bank, Standard Chartered Zambia Way",
        value: "ZM060228"
    },
    {
        name: "United Bank of Africa, Kitwe",
        value: "ZM370204"
    },
    {
        name: "United Bank of Africa, The United Bank of Zambia Ltd - Lusaka",
        value: "ZM180001"
    },
    {
        name: "United Bank of Africa, United Bank for Africa Zambia Cairo",
        value: "ZM370003"
    },
    {
        name: "United Bank of Africa, United Bank for Africa Zambia H O",
        value: "ZM370099"
    },
    {
        name: "United Bank of Africa, United Bank for Africa Zambia H O Br",
        value: "ZM370001"
    },
    {
        name: "United Bank of Africa, United Bank for Africa Zambia Kamwala",
        value: "ZM370002"
    },
    {
        name: "United Bank of Africa, United Bank of Africa",
        value: "ZM370105"
    },
    {
        name: "Zambia Industrial Commercial Bank, ECL Mall Branch",
        value: "ZM140002"
    },
    {
        name: "Zambia Industrial Commercial Bank, Head Office",
        value: "ZM140000"
    },
    {
        name: "Zambia Industrial Commercial Bank, Lusaka Main Branch",
        value: "ZM140001"
    },
    {
        name: "Zambia National Commercial Bank, Acacia Park Branch",
        value: "ZM010086"
    },
    {
        name: "Zambia National Commercial Bank, Mukuba branch",
        value: "ZM010298"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Govt Complex",
        value: "ZM010084"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Kapiri Mposhi",
        value: "ZM012461"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Kitwe Industrial",
        value: "ZM010256"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Luanshya",
        value: "ZM010763"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Lusaka City Market",
        value: "ZM010074"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Lusaka CivicCentre",
        value: "ZM010067"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Lusaka Premium",
        value: "ZM010066"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Ndola Industrial",
        value: "ZM010164"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm Woodlands",
        value: "ZM010085"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Debt Recovery",
        value: "ZM010055"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Human Resources",
        value: "ZM010007"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Intl. Banking",
        value: "ZM010002"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Lusaka Centre",
        value: "ZM010052"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Lusaka Kwacha",
        value: "ZM010053"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Lusaka North end",
        value: "ZM010041"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Comm. Ndola West",
        value: "ZM010154"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Avondale",
        value: "ZM010073"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Chingola",
        value: "ZM010349"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Chipata",
        value: "ZM011160"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Chirundu",
        value: "ZM013582"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Chisamba",
        value: "ZM014508"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Choma",
        value: "ZM011259"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Digital Banking",
        value: "ZM010093"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial H O",
        value: "ZM010001"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Itezhi-Tezhi",
        value: "ZM014779"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Kabwe",
        value: "ZM010946"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Kafue",
        value: "ZM013458"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Kasama",
        value: "ZM010862"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Kawambwa",
        value: "ZM012104"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Kitwe Obote",
        value: "ZM010245"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Livingstone",
        value: "ZM011044"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Lundazi",
        value: "ZM012571"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Maamba",
        value: "ZM014070"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Manda Hill",
        value: "ZM010078"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mansa",
        value: "ZM011948"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mazabuka",
        value: "ZM013647"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mfuwe",
        value: "ZM012606"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mkushi",
        value: "ZM012309"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mongu",
        value: "ZM013151"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Monze",
        value: "ZM013757"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mpika",
        value: "ZM011865"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Mufulira",
        value: "ZM010543"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Namwala",
        value: "ZM013372"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial North mead",
        value: "ZM010075"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Petauke",
        value: "ZM014305"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Plc Nakonde",
        value: "ZM011596"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Senanga2",
        value: "ZM015181"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Siavonga",
        value: "ZM013869"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Solwezi",
        value: "ZM012868"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Treasury",
        value: "ZM010018"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial Xapit",
        value: "ZM010083"
    },
    {
        name: "Zambia National Commercial Bank, Zambia National Commercial ZACC",
        value: "ZM010016"
    },
    {
        name: "Zambia National Commercial Bank, ZM National Comm. Cairo Business Centre",
        value: "ZM010040"
    },
    {
        name: "Zambia National Commercial Bank, ZM National Comm. Govt Business Centre",
        value: "ZM010050"
    },
    {
        name: "Zambia National Commercial Bank, ZM National Comm. Kitwe Clearing Centre",
        value: "ZM010217"
    },
    {
        name: "Zambia National Commercial Bank, ZM National Comm. Lusaka Business Centre",
        value: "ZM010003"
    },
    {
        name: "Zambia National Commercial Bank, ZM National Comm. Ndola Business Centre",
        value: "ZM010142"
    }

]

export const ghanaBusinessPaymentTypes = [
    "GHS::Bank"
]

// export const europeBusinessPaymentTypes = [
//     "EUR::Bank",
//     "GBP::Bank"
// ]

export const waemuBusinessPaymentTypes = [
   "XOF::Bank"
]

export const southAfricaBusinessPaymentTypes = [
    "ZAR::Bank"
]

export const cemacBusinessPaymentTypes = [
    "XAF::Bank"
]

export const usBusinessPaymentTypes = [
    "USD::Bank"
]

export const kenyaBusinessPaymentTypes = [
    "KES::Bank"
]

export const egyptBusinessPaymentTypes = [
    "EGP::Bank"
]

export const nigeriaBusinessPaymentTypes = [
    "NigeriaUSD::Bank"
]

export const azaBusinessCountries = [
    "CEMAC Region/XAF",
    "Egypt",
    // "Europe/SEPA",
    "Ghana",
    "Kenya",
    "Nigeria",
    "South Africa",
    // "United States",
    "WAEMU Region/XOF"
]

export const entityTypes = [
    {
        name: "Sole Proprietorship",
        value: "sole_proprietorship"
    },
    {
        name: "Partnership",
        value: "partnership"
    },
    {
        name: "Privately Owned Company (Limited Company)",
        value: "privately_owned_company"
    },
    {
        name: "Publicly Listed Company (PLC)",
        value: "publicly_owned_company"
    },
    {
        name: "Government Owned Entity Trusts",
        value: "government_owned_entity"
    },
    {
        name: "GO (Majority Owned Subsidiary of State-Owned Company)",
        value: "go"
    },
    {
        name: "Financial Institution",
        value: "financial_institution"
    }
]

export const natureOfBusinessTypes = [
    {
        name: "Personal",
        value: "personal"
    },
    {
        name: "Agriculture and Hunting",
        value: "agriculture_and_hunting"
    },
    {
        name: "Forestry",
        value: "forestry"
    },
    {
        name: "Fishing",
        value: "fishing"
    },
    {
        name: "Agricultural By-Products",
        value: "agricultural_by_products"
    },
    {
        name: "Coal Mining",
        value: "coal_mining"
    },
    {
        name: "Oil Mining",
        value: "oil_mining"
    },
    {
        name: "Iron Ore Mining",
        value: "iron_ore_mining"
    },
    {
        name: "Other Metal and Diamond Mining",
        value: "other_metal_and_diamond_mining"
    },
    {
        name: "Other Mineral Mining",
        value: "other_mineral_mining"
    },
    {
        name: "Manufacture of Food/Drink/Tobacco",
        value: "manufacturing_of_food_drink_tobacco"
    },
    {
        name: "Manufacture of Textiles/Leather/Fur/Furniture",
        value: "manufacturing_of_textiles_leather_fur_furniture"
    },
    {
        name: "Manufacture of Wooden Products/Furniture",
        value: "manufacture_of_wooden_products_furniture"
    },
    {
        name: "Manufacture of Paper/Pulp/Allied Products",
        value: "manufacture_of_paper_pulp_allied_products"
    },
    {
        name: "Manufacture Of Chemicals Medical Petroleum Rubber Plastic Products",
        value: "manufacture_of_chemicals_medical_petroleum_rubber_plastic_products"
    },
    {
        name: "Manufacture Of Pottery China Glass Stone",
        value: "manufacture_of_pottery_china_glass_stone"
    },
    {
        name: "Manufacture Of Iron Steel Non-Ferrous Metals Basic Industries",
        value: "manufacture_of_iron_steel_non_ferrous_metals_basic_industries"
    },
    {
        name: "Manufacture Of Metal Products Electrical And Scientific Engineering",
        value: "manufacture_of_metal_products_electrical_and_scientific_engineering"
    },
    {
        name: "Manufacture Of Jewelry Musical Instruments Toys",
        value: "manufacture_of_jewelry_musical_instruments_toys"
    },
    {
        name: "Electricity, Gas And Water",
        value: "electricity_gas_and_water"
    },
    {
        name: "Construction",
        value: "construction"
    },
    {
        name: "Wholesale Trade",
        value: "wholesale_trade"
    },
    {
        name: "Retail Trade",
        value: "retail_trade"
    },
    {
        name: "Catering Incl. Hotels",
        value: "catering_incl_hotels"
    },
    {
        name: "Transport Storage",
        value: "transport_storage"
    },
    {
        name: "Communications",
        value: "communications"
    },
    {
        name: "Finance And Holding Companies",
        value: "finance_and_holding_companies"
    },
    {
        name: "Insurance",
        value: "insurance"
    },
    {
        name: "Business Services",
        value: "business_services"
    },
    {
        name: "Real Estate Development Investment",
        value: "real_estate_development_investment"
    },
    {
        name: "Central State Governments",
        value: "central_state_governments"
    },
    {
        name: "Community Services Defence Police Prisons Etc",
        value: "community_services_defence_police_prisons_etc"
    },
    {
        name: "Social Services Education Health Care",
        value: "social_services_education_health_care"
    },
    {
        name: "Personal Services - Leisure Services",
        value: "personal_services_leisure_services"
    },
    {
        name: "Personal Services - Domestic Laundry Repairs",
        value: "personal_services_domestic_laundry_repairs"
    },
    {
        name: "Personal Services - Embassies",
        value: "personal_services_embassies_international_organisations"
    }
]

export const cryptoList = [
    {
        name: "Bitcoin (BTC)",
        value: "btc"
    },
    // {
    //     name: "Ripple (XRP)",
    //     value: "xrp"
    // },
    {
        name: "Solana (SOL)",
        value: "sol"
    },
    {
        name: "Litecoin (LTC)",
        value: "ltc"
    },
    // {
    //     name: "Matic (MATIC)",
    //     value: "matic"
    // },
    {
        name: "Tether (USDT)",
        value: "usdt"
    },
    {
        name: "Binance Coin (BNB)",
        value: "bnb"
    },
    {
        name: "Ethereum (ETH)",
        value: "eth"
    }
];

export const cryptoShortName: any = {
    "Bitcoin (BTC)": "btc",
    // "Ripple (XRP)": "xrp",
    "Solana (SOL)": "sol",
    "Litecoin (LTC)": "ltc",
    // "Matic (MATIC)": "matic",
    "Tether (USDT)": "usdt",
    "Binance Coin (BNB)": "bnb",
    "Ethereum (ETH)": "eth"
};