import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import styles from "../../../styles/styles.module.css";
import s from "./Crypto.module.css";
import Wallets from "./Wallets/Wallets";
import Transactions from "./Transactions/Transactions";
import MyBtn from "../../../components/ui/MyBtn/MyBtn";
import { useAppDispatch } from "../../../store/store";
import { useAppSelector } from "../../../hooks/redux";
import { mercuryoLoginThunk, mercuryoRegisterThunk } from "../../../store/reducers/ActionCreators";

const Crypto = () => {

    const dispatch = useAppDispatch();
    const cryptoEnabled = useAppSelector((state) => state.mercuryoReducer.cryptoEnabled);

    const token = useAppSelector((state) => state.userReducer.user.userToken);

    const [widgetCredentials, setWidgetCredentials]: any = useState(null);

    const handleEnableCrypto = async () => {
        try {
            await dispatch(mercuryoRegisterThunk(token));
        } catch (error) {
            console.error("Error enabling crypto:", error);
        }
    };

    useEffect(() => {
        const checkLoginStatus = async () => {
            try {
                await dispatch(mercuryoLoginThunk(token)).then((res: any) =>
                    setWidgetCredentials(res.data.data)
                );
            } catch (error) {
                console.error("User is not logged in:", error);
            }
        };
        checkLoginStatus();
    }, [dispatch, token]);
    
    const enableCrypto = () => (
        <div>
            <div className={s.noTransactionWrapper}>
                <p className={s.transactionText}>
                    <b>In order to make crypto payments, enable this feature</b>
                    <br />
                    <br />
                    By enabling this feature you agree to{" "}
                    <b>
                        <a
                            rel="noreferrer"
                            href="https://mercuryo.io/legal/terms/"
                            target="_blank"
                        >
                            Terms of Service
                        </a>
                    </b>
                </p>
                <div className={s.buttonWrapper}>
                    <MyBtn
                        title={"Enable Crypto"}
                        large
                        onClick={handleEnableCrypto}
                        type={"submit"}
                    />
                </div>
            </div>
        </div>
    );

    return (
        <div className={s.container}>
            <Tabs>
                <TabList className={styles.tabstitle_block}>
                    <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Wallets</Tab>
                    <Tab selectedClassName={styles.active_tab} className={styles.tabtitle}>Transactions</Tab>
                </TabList>
                <TabPanel>
                    {cryptoEnabled ? <Wallets widgetCredentials={widgetCredentials} token={token} /> : enableCrypto()}
                </TabPanel>
                <TabPanel>
                    {cryptoEnabled ? <Transactions widgetCredentials={widgetCredentials} /> : enableCrypto()}
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default Crypto;
