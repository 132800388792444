import React, {useEffect} from 'react';
import { NavLink, useLocation, useNavigate} from "react-router-dom";
import s from "./SideMenu.module.css";
import classnames from "classnames";
import {LogoutThunk} from "../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import './index.css'
import closePopup from "../../assets/images/mobile/close_popup.svg";
import { brandTheme } from '../../config/config';

type PropsType = {
    setIsActive?: (boolean: boolean) => void
    isSide?: boolean
}

const SideMenu = (props: PropsType) => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const kycStatus = useAppSelector(state => state.userReducer.user.flags.kycStatus)
    const accType: string | null = useAppSelector(state => state.userReducer.user.userData.accountType)

    const location = useLocation()

    const handleLogout = () => {
        dispatch(LogoutThunk())
            .then(() => {
                navigate("/login");
            })
    }

    const kycIsValid = () => {
        if (!kycStatus) {
            return false
        } else if (kycStatus === "New") {
            return false
        } else if (kycStatus === "Rejected") {
            return false
        } else {
            return true
        }
    }

    const {
        setIsActive
    } = props;

    useEffect(() => {
        return setIsActive && setIsActive(false)
    }, [location.pathname, setIsActive])

    return (
        <>
            {brandTheme.brandLogo &&
                <div className={s.logo_block}>
                    <img src={brandTheme.brandLogo} alt={brandTheme.brandLogoAlt} className={s.logo} width={brandTheme.brandLogoWidth} height={brandTheme.brandLogoHeight}/>
                    {
                        props.isSide ? <img src={closePopup} className={s.closeImg} alt="" onClick={() => {
                            // @ts-ignore
                            props.setIsActive(false);
                        }}/> : <></>
                    }

                </div>
            }
            {    // @ts-ignore
                !kycIsValid()
                    ? <></>
                    : <div className={s.links_wrapper}>
                        <NavLink to={`/personal_account/dashboard`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_dashboard"></div>
                            </div>
                            <div className="text_block">
                                Dashboard
                            </div>
                        </NavLink>
                        <div className={s.links_title}>services</div>
                        <NavLink to={`/personal_account/accounts`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_accounts"></div>
                            </div>
                            <div className="text_block">
                                Accounts
                            </div>
                        </NavLink>
                        <NavLink to={`/personal_account/transfers`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_transfers"></div>
                            </div>
                            <div className="text_block">
                                Transfers
                            </div>
                        </NavLink>
                        <NavLink to={`/personal_account/templates`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_templates"></div >
                            </div>
                            <div className="text_block">
                                Recipients
                            </div>
                        </NavLink>
                        <NavLink to={`/personal_account/cards`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_cards"></div >
                            </div>
                            <div className="text_block">
                                My Cards
                            </div>
                        </NavLink>
                        <NavLink to={`/personal_account/crypto`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_crypto"></div >
                            </div>
                            <div className="text_block">
                                Crypto
                            </div>
                        </NavLink>
                        <NavLink to={`/personal_account/currency`}
                                 className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                            <div className="img_block">
                                <div className="img_currency"></div>
                            </div>
                            <div className="text_block">
                                Currency
                            </div>
                        </NavLink>
                    </div>

            }

            <div className={s.links_wrapper}>
                <div className={classnames(s.links_title, s.services_block)}>Account</div>

                <NavLink to={`/personal_account/security`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_security"></div>
                    </div>
                    <div className="text_block">
                        Security
                    </div>
                </NavLink>
                <NavLink to={`/personal_account/${accType}/settings`}
                         className={({isActive}) => classnames(s.nav_item, isActive ? "active" : 'inactive')}>
                    <div className="img_block">
                        <div className="img_settings"></div>
                    </div>
                    <div className="text_block">
                        Settings
                    </div>
                </NavLink>

                <div className={classnames(s.logout, 'inactive')} onClick={() => handleLogout()}>
                    <div className="img_block">
                        <div className="img_logout"></div>
                    </div>
                    <div className={s.text}>Logout</div>
                </div>
            </div>


        </>
    );
};

export default SideMenu;
