import React, { useState } from "react";
import MyBtn from "../../../../components/ui/MyBtn/MyBtn";
import s from "./Wallets.module.css";
import CustomModal from "../../../../components/ui/CustomModal/CustomModal";
import { Formik } from "formik";
import CustomDropdown from "../../../../components/ui/CustomDropdown/CustomDropdown";
import TransparentButton from "../../../../components/ui/TransparentButton/TransparentButton";
import Icon, { EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as Back } from "../../../../assets/images/personalAccount/back.svg";
import * as yup from "yup";
import MyInput from "../../../../components/ui/MyInput/MyInput";
import { cryptoList, cryptoShortName } from "../../../../constants/StaticData";
import { Button, Dropdown, Layout, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomModalTabList from "../../../../components/ui/CustomModalTabList/CustomModalTabList";
import { appConfig } from "../../../../config/config";
import { generateSHASignature } from "../../../../helpers/SHACalculator";
import {
    createMercuryoTransactionThunk,
    createWalletThunk,
} from "../../../../store/reducers/ActionCreators";
import { useAppDispatch } from "../../../../store/store";
import { useAppSelector } from "../../../../hooks/redux";

type PropsType = {
    widgetCredentials: any;
    token: string;
};

const Wallets = (props: PropsType) => {
    const dispatch = useAppDispatch();

    const walletsList = useAppSelector(
        (state) => state.walletReducer.walletsData
    );

    const cryptoListDropdown: any[any] = cryptoList.map(
        (crypto: any) => crypto.name
    );

    const [showCreateWalletModal, setShowCreateWalletModal] = useState(false);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
    const [selectedWallet, setSelectedWallet]: any[any] = useState(null);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [showCreateTransactionModal, setShowCreateTransactionModal] =
        useState(false);
    const [modalDismissAllowed, setModalDismissAllowed] = useState(true); 

    const validationSchema = yup.object({
        currency: yup.string().required("Required field"),
        name: yup.string().required("Required field").max(90, "max 90 symbols"),
        address: yup
            .string()
            .required("Required field")
            .max(90, "max 90 symbols"),
    });

    const handleMenuClick = async (key: string, record: any) => {
        // console.log("selected record", record);
        setSelectedWallet(record);
        setShowCreateTransactionModal(true);
    };

    const addWallet = (values: any) => {
        dispatch(createWalletThunk(props.token, values)).then((res) => {
            if (res.status === 201) {
                setShowCreateWalletModal(false);
                setSuccessMessage("Wallet has been created successfully");
                setIsSuccessModalOpen(true);
            } else {
                setErrorMessage("Opps something went wrong, contact support!");
                setIsErrorModalOpen(true);
            }
        });
    };

    const columns: ColumnsType<any> = [
        {
            title: "Crypto",
            dataIndex: "currency",
            render: (currency: string) => (
                <div className={s.cryptoDetail}>
                    <img
                        src={`/crypto/${cryptoShortName[currency]}.svg`}
                        alt={`Crypto Icon ${cryptoShortName[currency]}`}
                    />
                    <div className={s.cryptoText}>
                        <div>{currency}</div>
                    </div>
                </div>
            ),
        },
        {
            title: "Wallet Name",
            dataIndex: "name",
        },
        {
            title: "Wallet Address",
            dataIndex: "address",
        },
        {
            title: "Status",
            dataIndex: "status",
            render: (status: string) => {
                let color = "";
                switch (status) {
                    case "Pending":
                        color = "yellow";
                        break;
                    case "Approved":
                        color = "green";
                        break;
                    case "Rejected":
                        color = "red";
                        break;
                    default:
                        color = "default";
                        break;
                }

                return <Tag color={color}>{status}</Tag>;
            },
        },
        {
            title: "Action",
            key: "action",
            render: (_, record) => {
                return (
                    <Dropdown
                        menu={{
                            items: [
                                {
                                    key: "createTransaction",
                                    label: "Create Transaction",
                                    // disabled: record.status !== "Approved",
                                }
                            ],
                            onClick: ({ key }) => handleMenuClick(key, record)
                        }}
                        trigger={["click"]}
                        disabled = {record.status !== "Approved"}
                    >
                        <Button
                            shape="circle"
                            icon={<EllipsisOutlined />}
                            onClick={(e) => e.preventDefault()}
                            disabled={record.status !== "Approved"}
                        />
                    </Dropdown>
                );
            }            
        },
    ];

    const noWallets = () => (
        <div>
            <div className={s.noWalletWrapper}>
                <div className={s.walletIcon}></div>
                <p className={s.walletText}>No Wallets</p>
                <div className={s.buttonWrapper}>
                    <MyBtn
                        title={"+ Add Wallet"}
                        large
                        onClick={() => setShowCreateWalletModal(true)}
                        type={"button"}
                    />
                </div>
            </div>
        </div>
    );

    const renderWallets = () => (
        <div className={s.walletsContainer}>
            <div className={s.walletsTableWrapper}>
                <div className={s.titleWrapper}>
                    <div>Crypto Wallets</div>
                    <div className={s.titleButtonWrapper}>
                        <div className={s.buttonWrapper}>
                            <MyBtn
                                title={"+ Add Wallet"}
                                medium
                                onClick={() => setShowCreateWalletModal(true)}
                                type={"button"}
                            />
                        </div>
                    </div>
                </div>
                <Layout>
                    <Table
                        bordered={false}
                        className={s.walletsTable}
                        columns={columns}
                        dataSource={walletsList}
                        pagination={{
                            pageSize: 10,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        scroll={{ x: "max-content" }}
                        rowKey="id"
                    />
                </Layout>
            </div>
        </div>
    );

    return (
        <>
            <CustomModal
                isModalOpen={showCreateWalletModal}
                setIsModalOpen={setShowCreateWalletModal}
            >
                <Formik
                    initialValues={{
                        currency: "",
                        name: "",
                        address: "",
                        referral: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={addWallet}
                >
                    {({ errors, touched, handleChange, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            <div className={s.modalFormItem}>
                                <div className={s.modalTitle}>
                                    Create a Wallet
                                </div>
                                <div className={s.modalDropdownSubtitle}>
                                    Select a Currency *
                                </div>
                                <div className={s.modalDropdown}>
                                    <CustomDropdown
                                        items={cryptoListDropdown}
                                        id={"currency"}
                                        name={"currency"}
                                        isError={errors.currency}
                                        touched={touched.currency}
                                        placeholder={""}
                                    />
                                    {errors.currency && touched.currency && (
                                        <div className={s.error}>
                                            {errors.currency}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={s.modalFormItem}>
                                <span className={s.modalDropdownSubtitle}>
                                    Wallet Name *
                                </span>
                                <div className={s.modalBlock}>
                                    <MyInput
                                        id={"name"}
                                        name={"name"}
                                        placeholder={"Wallet Name"}
                                        isError={errors.name}
                                        touched={touched.name}
                                        onChange={handleChange}
                                    />
                                    {touched.name && errors.name && (
                                        <div className={s.error}>
                                            {errors.name}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={s.modalFormItem}>
                                <span className={s.modalDropdownSubtitle}>
                                    Wallet Address *
                                </span>
                                <div className={s.modalBlock}>
                                    <MyInput
                                        id={"address"}
                                        name={"address"}
                                        placeholder={"Ie.ETH Wallet"}
                                        isError={errors.address}
                                        touched={touched.address}
                                        onChange={handleChange}
                                    />
                                    {touched.address && errors.address && (
                                        <div className={s.error}>
                                            {errors.address}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={s.modalFormItem}>
                                <span className={s.modalDropdownSubtitle}>
                                    Referral (Optional)
                                </span>
                                <div className={s.modalBlock}>
                                    <MyInput
                                        id={"referral"}
                                        name={"referral"}
                                        placeholder={"Referral"}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                            <div className={s.modalButtonsWrapper}>
                                <TransparentButton
                                    title={"Cancel"}
                                    style={{ width: 180 }}
                                    medium
                                    icon={
                                        <Icon
                                            component={() => <Back />}
                                            rev={undefined}
                                        />
                                    }
                                    onClick={() =>
                                        setShowCreateWalletModal(false)
                                    }
                                />
                                <MyBtn
                                    medium
                                    style={{ width: 180 }}
                                    title={"Continue"}
                                />
                            </div>
                        </form>
                    )}
                </Formik>
            </CustomModal>
            <CustomModal
                isModalOpen={isSuccessModalOpen}
                setIsModalOpen={setIsSuccessModalOpen}
            >
                <div className={s.status_flex}>
                    <div className={s.status_modal_title}>Success!</div>
                    <div className={s.status_modal_subtitle}>
                        {successMessage}
                    </div>
                    <div className={s.status_button_block}>
                        <MyBtn
                            title={"Ok"}
                            onClick={() => {
                                setIsSuccessModalOpen(false);
                            }}
                        />
                    </div>
                </div>
            </CustomModal>
            <CustomModal
                type="error"
                isModalOpen={isErrorModalOpen}
                setIsModalOpen={setIsErrorModalOpen}
            >
                <div className={s.status_flex}>
                    <div className={s.status_modal_title}>Oops!</div>
                    <div className={s.status_modal_subtitle}>
                        {errorMessage}
                    </div>
                    <div className={s.status_button_block}>
                        <MyBtn
                            buttonType="error"
                            title={"Ok"}
                            onClick={() => setIsErrorModalOpen(false)}
                        />
                    </div>
                </div>
            </CustomModal>
            <CustomModalTabList
                isModalOpen={showCreateTransactionModal}
                tabs={[
                    {
                        label: "Buy",
                        action: async () => {
                            try {
                                const signature = await generateSHASignature(
                                    selectedWallet?.address,
                                    appConfig.mercuryoWidgetSecret
                                );
                                // @ts-ignore
                                await mercuryoWidget.run({
                                    widgetId: appConfig.mercuryoWidgetId,
                                    host: document.getElementById(
                                        "mercuryo-widget-buy"
                                    ),
                                    signature: signature,
                                    initToken:
                                        props.widgetCredentials.init_token,
                                    initTokenType:
                                        props.widgetCredentials.init_type_token,
                                    type: "buy",
                                    currency: cryptoShortName[selectedWallet?.currency],
                                    fixCurrency: true,
                                    address: selectedWallet.address,
                                    onStatusChange: (data: any) => {
                                        console.log("status changed: ", data);
                                        if (data.status === "pending") {
                                            setModalDismissAllowed(false);
                                        } else if (data.status === "paid") {
                                            setModalDismissAllowed(true);
                                            setShowCreateTransactionModal(false);
                                            setSuccessMessage("Transaction has been successfully made");
                                            setIsSuccessModalOpen(true);
                                            dispatch(
                                                createMercuryoTransactionThunk(
                                                    props.token,
                                                    data
                                                )
                                            );
                                        }
                                    },
                                });
                            } catch (error) {
                                console.error(
                                    "Error running Mercuryo widget:",
                                    error
                                );
                                setErrorMessage("Opps something went wrong, contact support!");
                                setIsErrorModalOpen(true);
                            }
                        },
                    },
                    {
                        label: "Sell",
                        action: async () => {
                            try {
                                const signature = await generateSHASignature(
                                    selectedWallet?.address,
                                    appConfig.mercuryoWidgetSecret
                                );
                                // @ts-ignore
                                await mercuryoWidget.run({
                                    widgetId: appConfig.mercuryoWidgetId,
                                    host: document.getElementById(
                                        "mercuryo-widget-sell"
                                    ),
                                    signature: signature,
                                    initToken:
                                        props.widgetCredentials.init_token,
                                    initTokenType:
                                        props.widgetCredentials.init_type_token,
                                    type: "sell",
                                    currency: cryptoShortName[selectedWallet?.currency],
                                    fixCurrency: true,
                                    address: selectedWallet.address,
                                    onStatusChange: (data: any) => {
                                        console.log("status changed: ", data);
                                        if (data.status === "pending") {
                                            setModalDismissAllowed(false);
                                        } else if (data.status === "succeeded") {
                                            setModalDismissAllowed(true);
                                            setShowCreateTransactionModal(false);
                                            setSuccessMessage("Transaction has been successfully made");
                                            setIsSuccessModalOpen(true);
                                            dispatch(
                                                createMercuryoTransactionThunk(
                                                    props.token,
                                                    data
                                                )
                                            );
                                        }
                                    },
                                });
                            } catch (error) {
                                console.error(
                                    "Error running Mercuryo widget:",
                                    error
                                );
                                setErrorMessage("Opps something went wrong, contact support!");
                                setIsErrorModalOpen(true);
                            }
                        },
                    },
                ]}
                tabContents={{
                    Buy: (
                        <div
                            id="mercuryo-widget-buy"
                            style={{ height: "500px" }}
                        />
                    ),
                    Sell: (
                        <div
                            id="mercuryo-widget-sell"
                            style={{ height: "500px" }}
                        />
                    ),
                }}
                onClose={() => modalDismissAllowed && setShowCreateTransactionModal(false)}
            />
            {walletsList.length >= 1 ? renderWallets() : noWallets()}
        </>
    );
};

export default Wallets;
