const generateSHASignature = async (address: string, secret: string) => {
    if (!address || !secret) {
        console.error("Address or Secret is missing");
        return null;
    }

    const input = address + secret;

    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    const hashBuffer = await crypto.subtle.digest("SHA-512", data);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
        .map((byte) => byte.toString(16).padStart(2, "0"))
        .join("");

    return hashHex;
};

export { generateSHASignature };