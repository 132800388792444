import { createSlice } from "@reduxjs/toolkit";

type MercuryoState = {
    transactionsData: Array<IMercuryoTransaction>;
    filters: IMercuryoFilters;
    cryptoEnabled: boolean;
};

export interface IMercuryoFilters {
    status?: string;
    currency?: string;
    paymentMethod?: string;
    dateFrom?: string;
    dateTo?: string;
}

export interface IMercuryoTransaction {
    amount: string;
    created_at: string;
    currency: string;
    email: string;
    fiat_amount: string;
    fiat_currency: string;
    id: string;
    merchant_transaction_id: string;
    payment_method: string;
    status: string;
    transaction_id: string;
    updated_at: string;
    widget_id: string;
}

const initialState: MercuryoState = {
    transactionsData: [],
    filters: {
        status: "",
        currency: "",
        paymentMethod: "",
        dateFrom: "",
        dateTo: "",
    },
    cryptoEnabled: false,
};

export const mercuryoSlice = createSlice({
    name: "mercuryo",
    initialState,
    reducers: {
        setMercuryoTransactionsData(state, action) {
            state.transactionsData = action.payload;
        },
        setMercuryoFilters(state, action) {
            state.filters = action.payload;
        },
        setCryptoEnabled(state, action) {
            state.cryptoEnabled = action.payload;
        },
        clearMercuryoSlice(state) {
            state.transactionsData = [];
            state.filters = {
                status: "",
                currency: "",
                paymentMethod: "",
                dateFrom: "",
                dateTo: "",
            };
            state.cryptoEnabled = false;
        },
    },
});

export const {
    setMercuryoTransactionsData,
    setMercuryoFilters,
    setCryptoEnabled,
    clearMercuryoSlice,
} = mercuryoSlice.actions;

export default mercuryoSlice.reducer;
