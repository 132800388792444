import { createSlice } from "@reduxjs/toolkit";

type WalletState = {
    walletsData: Array<IWallet>;
    filters: IWalletFilters;
    walletEnabled: boolean;
};

export interface IWallet {
    id: string;
    address: string;
    status: string;
}

export interface IWalletFilters {
    status?: string;
}

const initialState: WalletState = {
    walletsData: [],
    filters: {
        status: "",
    },
    walletEnabled: false,
};

export const walletSlice = createSlice({
    name: "wallet",
    initialState,
    reducers: {
        setWalletsData(state, action) {
            state.walletsData = action.payload;
        },
        setWalletFilters(state, action) {
            state.filters = action.payload;
        },
        setWalletEnabled(state, action) {
            state.walletEnabled = action.payload;
        },
        clearWalletSlice(state) {
            state.walletsData = [];
            state.filters = {
                status: "",
            };
            state.walletEnabled = false;
        },
    },
});

export const {
    setWalletsData,
    setWalletFilters,
    setWalletEnabled,
    clearWalletSlice,
} = walletSlice.actions;

export default walletSlice.reducer;
