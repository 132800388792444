import React, { useState, useEffect, useRef } from "react";
import s from "./CustomModalTabList.module.css";
import { motion } from "framer-motion";
import classNames from "classnames";

type Tab = {
    label: string;
    action?: () => void;
};

type ModalType = {
    isModalOpen: boolean;
    tabs: Tab[];
    onTabChange?: (tab: string) => void;
    tabContents: { [key: string]: React.ReactNode };
    onClose: () => void;
};

const CustomModalTabList = ({
    isModalOpen,
    tabs,
    onTabChange,
    tabContents,
    onClose,
}: ModalType) => {
    const [activeTab, setActiveTab] = useState(tabs[0].label);
    const modalRef = useRef<HTMLDivElement>(null);

    const handleTabClick = (tab: Tab) => {
        setActiveTab(tab.label);
        if (onTabChange) onTabChange(tab.label);
    };

    // Run the action when the activeTab changes and the modal is open
    useEffect(() => {
        const currentTab = tabs.find(tab => tab.label === activeTab);
        if (isModalOpen && currentTab && currentTab.action) {
            currentTab.action();
        }
    }, [activeTab, isModalOpen, tabs]);

    // Handle clicks outside the modal
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (isModalOpen) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isModalOpen, onClose]);

    return (
        <motion.div
            initial={{ opacity: 0, scale: 1 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
        >
            {isModalOpen ? (
                <div className={s.wrapper}>
                    <div ref={modalRef} className={s.content_wrapper}>
                        <div className={s.tabHeader}>
                            {tabs.map((tab) => (
                                <button
                                    key={tab.label}
                                    className={classNames(s.tabButton, {
                                        [s.activeTab]: tab.label === activeTab,
                                    })}
                                    onClick={() => handleTabClick(tab)}
                                >
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                        <div className={s.content}>
                            {tabContents[activeTab]}
                        </div>
                    </div>
                </div>
            ) : null}
        </motion.div>
    );
};

export default CustomModalTabList;
