import React from "react";
import { useSelector } from "react-redux";
import { Layout, Table, Tag } from "antd";
import s from "./Transactions.module.css";
import { RootState } from "../../../../store/store";

type PropsType = {
    widgetCredentials: any;
};

const Transactions = (props: PropsType) => {
    const transactionsData = useSelector(
        (state: RootState) => state.mercuryoReducer.transactionsData
    );

    const columns = [
        {
            title: "Transaction ID",
            dataIndex: "transactionId",
            key: "transactionId",
            width: 200
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            width: 200
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            width: 150
        },
        {
            title: "Fiat Amount",
            dataIndex: "fiatAmount",
            key: "fiatAmount",
            width: 150
        },
        {
            title: "Fiat Currency",
            dataIndex: "fiatCurrency",
            key: "fiatCurrency",
            width: 150
        },
        {
            title: "Network",
            dataIndex: "network",
            key: "network",
            width: 150
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (value: string) => {
                return value === "paid" ? (
                    <Tag color="green">Paid</Tag>
                ) : (
                    <Tag color="red">{value}</Tag>
                );
            },
        },
    ];
    

    const renderTransactions = () => (
        <div className={s.transactionsContainer}>
            <div className={s.transactionsTableWrapper}>
                <div className={s.titleWrapper}>
                    <div>Crypto Transactions</div>
                </div>
                <Layout>
                    <Table
                        bordered={false}
                        className={s.transactionsTable}
                        columns={columns}
                        dataSource={transactionsData}
                        pagination={{
                            pageSize: 5,
                            showTotal: (total, range) =>
                                `${range[0]}-${range[1]} of ${total} items`,
                        }}
                        scroll={{ x: "max-content" }}
                        rowKey={(record: any) => `${record.transactionId}-${record.currency}`}
                    />
                </Layout>
            </div>
        </div>
    );

    const noTransactions = () => (
        <div>
            <div className={s.noTransactionWrapper}>
                <div className={s.transactionIcon}></div>
                <p className={s.transactionText}>No Transactions</p>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {transactionsData.length >= 1
                ? renderTransactions()
                : noTransactions()}
        </React.Fragment>
    );
};

export default Transactions;
